import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from '../CollapsableInputCategory'
import SelectOption from '../SelectOption'
import SelectOptionFonts from '../SelectOptionFonts'
import NumberFieldOption from '../NumberFieldOption'
import SliderOption from '../SliderOption'
import ColorOption from '../ColorOption'
import TextOption from '../TextOption'
import CheckboxOption from '../CheckboxOption'
import {
  standardAnimationOptions,
  standardFontOptions,
  standardBackgroundAnimationOptions,
  standardAnimationOptionsEnabledBackground,
} from '../AnimationOptions'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

export default function AnimationInputExample(props) {
  const classes = useStyles()

  const [includeBackground, setIncludeBackground] = React.useState(1)

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="General settings">
        <TextOption
          size="double"
          element="e2"
          optionName="videoID"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Link to Youtube video"
          helpText=""
          defaultValue="www.youtube.com/watch?v=kJQP7kiw5Fk"
        />
        {!includeBackground && (
          <SelectOption
            element="e1"
            optionName="animation"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Animation"
            options={standardAnimationOptions()}
            defaultValue="zoom"
          />
        )}
        {includeBackground && (
          <SelectOption
            element="e1"
            optionName="animation"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Animation on inner elements"
            options={standardAnimationOptionsEnabledBackground()}
            defaultValue="instant"
            helpText="Change 'Background animation' instead, unless you really want to stack animations"
          />
        )}
        {includeBackground && (
          <SelectOption
            element="b1"
            optionName="animation"
            parentHandleUpdate={props.alertParentOptionChange}
            title=" Background animation"
            options={standardBackgroundAnimationOptions()}
            defaultValue="zoom"
          />
        )}
        <NumberFieldOption
          element="e1"
          optionName="animationDuration"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation duration, seconds"
          defaultValue="10"
        />

        <NumberFieldOption
          element="e1"
          optionName="animationInterval"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDelay"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Background settings">
        <CheckboxOption
          element="b1"
          optionName="include"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Include background"
          setFunction={setIncludeBackground}
          defaultValue={1}
          helpText={
            includeBackground
              ? 'Disabling resets all background settings'
              : 'Enabling resets current animation'
          }
        />
        {includeBackground && (
          <ColorOption
            element="b1"
            optionName="backgroundColor"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Background color"
            defaultValue="#ffffff"
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="width"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Width"
            defaultValue={90}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="height"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Height"
            defaultValue={90}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="borderR"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Border rounding"
            defaultValue={1}
            stepSize={1}
            min={0}
            max={50}
            minText="0%"
            maxText="50%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="opacity"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Background opacity"
            defaultValue={100}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <CheckboxOption
            element="b1"
            optionName="includeBgShadow"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Have background shadow"
            defaultValue={1}
          />
        )}
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Title text">
        <TextOption
          element="e1"
          optionName="text"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text"
          defaultValue="Watch my video"
        />

        {/* Using a new element since both texts should have same color */}
        <ColorOption
          element="c1"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />

        <SliderOption
          element="e1"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <SelectOptionFonts
          element="e1"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Open Sans Condensed, sans-serif"
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Video">
        <SliderOption
          element="e2"
          optionName="width"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Thumbnail size"
          defaultValue={65}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={1}
        />

        <SliderOption
          element="e3"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={20}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />

        <SliderOption
          element="e3"
          optionName="width"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text width wrap"
          defaultValue={65}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={1}
        />
        <SelectOptionFonts
          element="e3"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Open Sans Condensed, sans-serif"
        />
      </CollapsableInputCategory>
    </div>
  )
}
