import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
//import ColorPicker from 'react-color';
//import ColorPicker from "react-color-picker-text";
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '95px',
    maxHeight: '95px',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  labelText: {
    textAlign: 'left',
  },
  colorInput: {
    borderWidth: '1px',
    width: '100%',
    height: '100%',
    border: 'none',
  },
}))

export default function NumberFieldOption(props) {
  const classes = useStyles()

  const [color, setColor] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)

  const updatesQueued = useRef(0)

  const handleChange = (name) => (event) => {
    // To update text below preview (showing the color hex code)
    setColor(event.target.value)

    // Url cant contain the # character, unable to GET the color, the # is re-added in createClass.php
    let urlSafeColor = event.target.value
    urlSafeColor = urlSafeColor.replace('#', '')

    updatesQueued.current = updatesQueued.current + 1

    // Only alert parent (update ui) with last change, and only take update if no change in 100 ms
    let t = setTimeout(function () {
      updatesQueued.current = updatesQueued.current - 1

      if (updatesQueued.current === 0) {
        props.parentHandleUpdate(
          props.element + '_' + props.optionName,
          urlSafeColor
        )
      }
    }, 100)
  }

  if (!valueChanged) {
    let urlSafeColor = props.defaultValue
    urlSafeColor = urlSafeColor.replace('#', '')
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      urlSafeColor
    )
    setValueChanged(true)
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.labelText}>{props.title}</Typography>
          <input
            style={{ border: '1px solid #efefef' }}
            type="color"
            className={classes.colorInput}
            onChange={handleChange()}
            value={color}
          />
          <FormHelperText>{color}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}
