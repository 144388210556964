// these are for the dropdown menus of different popups

export function standardAnimationOptions() {
  return [
    ['instant', 'Instant'],
    ['circlePop', 'Circle emerge'],
    ['polygonPop', 'Polygon emerge'],
    ['zoom', 'Zoom'],
    ['squish', 'Squish'],
    ['zoomClick', 'Zoom click'],
    ['zoomJump', 'Zoom jump'],
    ['fade', 'Fade'],
    ['swipeRight', 'Swipe right'],
    ['swipeLeft', 'Swipe left'],
    ['swipeUp', 'Swipe up'],
    ['swipeDown', 'Swipe down'],
    ['spinClockwise', 'Spin clockwise'],
    ['spinCounterClockwise', 'Spin anticlockwise'],
  ]
}

export function standardAnimationOptionsEnabledBackground() {
  return [
    ['instant', 'Follow background animation'],
    ['circlePop', 'Circle emerge'],
    ['polygonPop', 'Polygon emerge'],
    ['zoom', 'Zoom'],
    ['zoomClick', 'Zoom click'],
    ['zoomJump', 'Zoom jump'],
    ['fade', 'Fade'],
    ['swipeRight', 'Swipe right'],
    ['swipeLeft', 'Swipe left'],
    ['swipeUp', 'Swipe up'],
    ['swipeDown', 'Swipe down'],
    ['spinClockwise', 'Spin clockwise'],
    ['spinCounterClockwise', 'Spin anticlockwise'],
  ]
}

export function standardBackgroundAnimationOptions() {
  return [
    ['instant', 'Instant'],
    ['circlePop', 'Circle emerge'],
    ['polygonPop', 'Polygon emerge'],
    ['zoom', 'Zoom'],
    ['zoomClick', 'Zoom click'],
    ['zoomJump', 'Zoom jump'],
    ['fade', 'Fade'],
    ['swipeRight', 'Swipe right'],
    ['swipeLeft', 'Swipe left'],
    ['swipeUp', 'Swipe up'],
    ['swipeDown', 'Swipe down'],
    ['spinClockwise', 'Spin clockwise'],
    ['spinCounterClockwise', 'Spin anticlockwise'],
  ]
}

// used for font dropdown, first value is internal, second is the one visible (I think)

export function standardFontOptions() {
  return [
    ['Arial, sans-serif', 'Arial'],
    ['Arial Black, Gadget, sans-serif', 'Arial Black'],
    //['Arial, Helvetica, sans-serif', 'Arial+Helvetica'],
    ['Comic Sans MS, cursive, sans-serif', 'Comic Sans MS'],
    ['Courier, monospace', 'Courier'],
    ['Courier New, monospace', 'Courier New'],
    ['Geneva, sans-serif', 'Geneva'],
    ['Helvetica, sans-serif', 'Helvetica'],
    ['Impact, Charcoal, sans-serif', 'Impact'],
    ['Lucida, sans-serif', 'Lucida'],
    ['Monaco, monospace', 'Monaco'],
    ['Open Sans Condensed, sans-serif', 'Open sans'],
    ['Tahoma, sans-serif', 'Tahoma'],
    ['Trebuchet MS, Helvetica, sans-serif', 'Trebuchet MS'],
    ['Verdana, sans-serif', 'Verdana'],

    //['', ''],
    //['Tahoma, Geneva, sans-serif', 'Tahoma/Geneva'],
  ]
}
