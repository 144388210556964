import React, { useRef, useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'

import queryString from 'query-string'
const urlParameters = queryString.parse(window.location.search)

const useStyles = makeStyles((theme) => ({
  root: {
    // TODO do a better fix to remvoe scrollar then this and the mess in the second paragraph
    height: '100vh',
    background: '#ffffff',
    paddingTop: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  accountCard: {
    marginTop: theme.spacing(0),

    paddingBottom: theme.spacing(2),
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
}))

export default function SignUp() {
  const classes = useStyles()
  const ref = useRef(null)

  const [height, setHeight] = React.useState(123)
  const [width, setWidth] = React.useState(124)

  useEffect(() => {
    setHeight(ref.current.clientHeight)
    setWidth(ref.current.clientWidth)
  })

  function getOriginDomain() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:8000'
    }
    return window.location.origin
  }

  return (
    <div className={classes.root} ref={ref}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Card mt={4} className={classes.accountCard}>
          <div className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              This could be something from another website, for example a
              donation goal.
            </Typography>
            <Typography variant="h5" gutterBottom>
              The resolution can be changed when using a browser source. The
              browser source will look the same as this preview if the same
              resolution is used ({width}x{height} px).
            </Typography>
          </div>
        </Card>
      </Container>
    </div>
  )
}
