import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  input: {
    maxHeight: 20,
  },
  selectEmpty: {},
  labelText: {
    textAlign: 'left',
  },
}))

export default function SelectOption(props) {
  const classes = useStyles()

  const [choice, setChoice] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)

  const handleChange = (name) => (event) => {
    setChoice(event.target.value)

    //update url in parent
    //TODO check if event.target.value is needed (setChoice is synchronous, so that choice can be used instead)
    setValueChanged(true)
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      event.target.value
    )
  }

  let options = []

  if (props.options) {
    options = props.options
  }

  if (!valueChanged) {
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      props.defaultValue
    )
  }

  //  value could be [1] later on to save url space, and do decoding in createclass
  const dropdownOptions = options.map((options) => (
    <MenuItem
      style={{ fontFamily: options[0] }}
      key={options[0]}
      value={options[0]}
    >
      {options[1]}
    </MenuItem>
  ))

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
      <div className={classes.root}>
        <FormControl className={classes.formControl} margin="dense">
          <Typography className={classes.labelText}>{props.title}</Typography>
          <Select
            style={{ fontFamily: choice }}
            value={choice}
            onChange={handleChange()}
            displayEmpty
            name="age"
            className={classes.selectEmpty}
            input={<OutlinedInput />}
          >
            {dropdownOptions}
          </Select>
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}

/* Usage example:
  <SelectOption
    element="em1"
    optionName="animation"
    parentHandleUpdate={props.alertParentOptionChange}
    title="Animation"
    options={[["zoom", "Zoom"],
              ["swipeRight", "Swipe right"],
              ["swipeLeft", "Swipe left"],
              ["swipeUp", "Swipe up"],
              ["swipeDown", "Swipe down"],
            ]}
    defaultValue="zoom"
  />
*/
