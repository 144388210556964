import React from 'react'
import { withStyles } from '@material-ui/core/styles'
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  //const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '10',
    backgroundColor: '#ffffff00',
  },
  infoBox: {
    float: 'left',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(6),

    height: '100%',
    //background: "white",
    //boxShadow: "0 0px 0px rgba(0,0,0,.2)",
    //border: "1px solid rgba(0, 0, 0, 0.12)",
    '@media (max-width:1280px)': {
      marginTop: theme.spacing(6),
      paddingTop: theme.spacing(0),
      marginBottom: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      textAlign: 'center',
    },
  },
  animationBox: {
    float: 'left',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0px 0px rgba(0,0,0,.2)',
    //boxShadow: "0 2px 4px rgba(0,0,0,.2)",
    border: '1px solid rgba(0, 0, 0, 0.12)',
    //marginRight: theme.spacing(2)
    boxShadow: '0 1px 5px rgba(0,0,0,.2)',
  },
  cardTextContent: {
    flexGrow: 1,
  },
  cardMedia: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#aabfaa',
    backgroundColor: '#609732',
    backgroundColor: '#8abd5f',
    backgroundColor: '#88cc88',
    backgroundColor: '#cacaca',
    backgroundColor: '#dddddd',
    backgroundColor: 'white',
    backgroundColor: '#ffffff00',
    //height: "15vw"
    boxSizing: 'content-box',
    pointerEvents: 'none',
  },
  cardHeader: {
    //background: "rgba(0, 0, 0, 1)",
    //fontSize: "1.2rem",
    //marginTop: "-100px",
    paddingTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    color: 'black',
    zIndex: '11',
    fontSize: '20px',
    textAlign: 'left',
    width: '100%',
    float: 'left',
    //marginLeft: "15px",
  },
  topLevelCard: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  cardHeaderBody: {
    //background: "rgba(0, 0, 0, 1)",
    //fontSize: "1.2rem",
    //marginTop: "-100px",
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    color: 'black',
    zIndex: '11',
    fontSize: '16px',
    textAlign: 'left',
    float: 'left',
    marginRight: '18px',
  },
  aspectRationFixer: {
    position: 'relative',
    //paddingTop: '56.25%', // 16:9
    paddingBottom: '75.0%', // 4:3
  },
  continueButton: {
    background: '#66b574',
    width: '130px',
    height: '40px',
    display: 'flex',
    position: 'absolute',
    right: '0px',
    bottom: '1%',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  costText: {
    //display: "flex",
    marginBottom: theme.spacing(2),
    position: 'absolute',
    left: '0px',
    bottom: '0%',
  },
  insideInformation: {
    position: 'relative',
    height: '100%',
    '@media (max-width:1280px)': {
      height: 'calc(100% - 74vw)',
      marginTop: theme.spacing(6),
      paddingTop: theme.spacing(0),
      marginBottom: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  continueButtonText: {
    color: 'black',
    fontSize: '14px',
  },
  costTextInfo: {
    color: 'black',
  },
})

DisplayCard.propTypes = {
  data: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

//const classes = styles();
function DisplayCard(props) {
  const { classes } = props

  // TODO should these be removed?
  function _in() {
    //console.log("inside card");
  }

  function _out() {
    //console.log("outside card");
  }

  return (
    <Grid container alignItems="flex-start" className={classes.topLevelCard}>
      {/* ANIMATION BOX */}
      {/* this is stupid, fix this */}
      <Grid
        className={classes.animationBox}
        item
        key={1}
        xs={12}
        sm={12}
        md={12}
        lg={3}
        xl={3}
      >
        <Link href={props.link}>
          <Card className={classes.card} onMouseEnter={_in} onMouseLeave={_out}>
            <div className={classes.aspectRationFixer}>
              <object
                className={classes.cardMedia}
                type="text/html"
                data={props.data}
              ></object>
            </div>
          </Card>
        </Link>
      </Grid>

      {/* INFO BOX */}
      <Grid
        className={classes.infoBox}
        item
        key={2}
        xs={12}
        sm={12}
        md={12}
        lg={4}
        xl={4}
      >
        <Typography gutterBottom variant="h5" className={classes.cardHeader}>
          {props.header}
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          className={classes.cardHeaderBody}
        >
          {props.body}
        </Typography>
        <div className={classes.insideInformation}>
          <Typography gutterBottom variant="body1" className={classes.costText}>
            {'Quota: ' + props.quotaCost + ' '}
            <Link
              href="/watermark-info"
              target="_blank"
              className={classes.costTextInfo}
            >
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '14px',
                  //border: '1px solid black',
                  //borderRadius: '50%',
                }}
              >
                {' (?)'}
              </span>
            </Link>
          </Typography>
          <Link href={props.link}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.continueButton}
            >
              <Typography className={classes.continueButtonText} align="center">
                Create
              </Typography>
            </Button>
          </Link>
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(DisplayCard)
