import { createMuiTheme } from '@material-ui/core/styles';

const standardTheme = createMuiTheme({
  typography: {
    "color": "white",
    fontFamily: [
      'IBM Plex Sans',
      'Helvetica',
      'Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h1 : {
      "fontWeight": "500",
    },
    h2 : {
      "fontWeight": "700",
    },
    h3 : {
      "fontWeight": "500",
    },
    h4 : {
      "fontWeight": "500",
    },
    h5 : {
      "fontWeight": "500",
    },
    body1 : {
      "fontWeight": "400",
    },
  },
});

export default standardTheme
