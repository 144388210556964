import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Card from '@material-ui/core/Card'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import BackupIcon from '@material-ui/icons/Backup'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/common/Copyright'
import standardTheme from '../common/standardTheme'

import queryString from 'query-string'
const urlParameters = queryString.parse(window.location.search)

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    background: '#ffffffff',
    color: 'black',
    width: '60',
    height: '60',
    fontSize: '50em',
    //background: "#222",
    //backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: '2vw',
    marginTop: '5vw',
    marginLeft: '3vw',
    marginRight: '3vw',
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  container: {
    marginTop: '10vw',
    padding: theme.spacing(0),
  },
  errorText: {
    marginTop: theme.spacing(3),
  },
  uploadedStyle: {
    marginTop: theme.spacing(3),
  },
  notUploadedStyle: {
    marginTop: theme.spacing(3),
    //display: 'none',
  },
  informationText: {
    marginTop: theme.spacing(3),
  },
  disclaimerText: {
    color: 'rgba(0, 0, 0, 0.54);',
    marginLeft: theme.spacing(7),
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(5),
  },
  allowedText: {
    fontSize: '14px',
    marginTop: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54);',
  },
}))

export default function UploadImage() {
  const classes = useStyles()

  let url_string = window.location.href //window.location.href
  let url = new URL(url_string)
  let error = url.searchParams.get('error')
  let uploadStatus = url.searchParams.get('uploadStatus')

  const [uc, setuc] = React.useState(classes.notUploadedStyle)

  function onChange(target) {
    console.log('changing')
    setHasUploaded(true)
    setuc(classes.uploadedStyle)
    document.forms['imageSubmissionForm'].submit();

    // TODO see if using window.close() to close tab works
  }

  const [hasUploaded, setHasUploaded] = React.useState(false)

  const displaySelectButton = !hasUploaded && uploadStatus !== 'successful'
  const displayUploadButton = hasUploaded && uploadStatus !== 'successful'

  return (
    <ThemeProvider theme={standardTheme}>
      <Container className={classes.container} component="main" maxWidth="sm">
        <Card className={classes.card}>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <BackupOutlinedIcon fontSize="large" />
            </Avatar>
            <Typography style={{ fontWeight: 700 }} component="h1" variant="h5">
              Upload image
            </Typography>
            <Typography
              className={classes.allowedText}
              component="h3"
              variant="h6"
            >
              JPG, JPEG, PNG & GIF files are allowed.
            </Typography>
            {hasUploaded && (
              <Typography
                className={classes.informationText}
                component="h5"
                variant="h6"
              >
                File has been selected
              </Typography>
            )}
            {uploadStatus && uploadStatus === 'successful' && (
              <Typography
                className={classes.informationText}
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Image was successfully uploaded. You can close this tab to
                return.
              </Typography>
            )}

            {error && error === 'unknownError' && (
              <Typography
                className={classes.informationText}
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Unknown error, please try again
              </Typography>
            )}

            {error && error === 'notAPicture' && (
              <Typography
                className={classes.informationText}
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                File is not an image
              </Typography>
            )}

            {error && error === 'notAPicture2' && (
              <Typography
                className={classes.informationText}
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Sorry, only JPG, JPEG, PNG & GIF files are allowed.
              </Typography>
            )}

            {uploadStatus !== 'successful' && (
              <form
                action="./backend/uploadImage.php"
                method="post"
                enctype="multipart/form-data"
                name="imageSubmissionForm"
                className={classes.form}
              >
                <input
                  style={{ display: 'none' }}
                  className={classes.uploadInput}
                  type="file"
                  name="fileToUpload"
                  onChange={onChange}
                  id="fileToUpload"
                />

                {displaySelectButton && (
                  <label htmlFor="fileToUpload">
                    <Button
                      variant="contained"
                      component="span"
                      className={classes.confirmUploadButton}
                      size="large"
                      color="primary"
                    >
                      Select image
                    </Button>
                  </label>
                )}

                <input
                  id="uploadSubmit"
                  type="submit"
                  style={{ display: 'none' }}
                  name="submitButton"
                />
                {displayUploadButton && (
                  <label htmlFor="uploadSubmit">
                    <Button
                      variant="contained"
                      component="span"
                      className={classes.confirmUploadButton}
                      size="large"
                      color="primary"
                    >
                      Upload the file
                    </Button>
                  </label>
                )}
              </form>
            )}

            <Typography
              className={classes.disclaimerText}
              component="h5"
              variant="body"
            >
              You are only allowed to upload and use legal content that you are
              the owner of. By uploading, you acknowledge that StreamZombie has
              no liability or responsibility for uploaded content.
            </Typography>
          </div>
        </Card>
        <Box mt={6}>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  )
}
