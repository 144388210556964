import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import {
  getCookie,
  setCookie,
  getStoredJWTCookieData,
} from '../../common/cookie'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const useStyles = makeStyles((theme) => ({
  popupRoot: {
    alignItems: 'center',
    textAlign: 'center',
  },
  urlOutputField: {
    width: '80%',
    marginTop: theme.spacing(1),
  },
  supportingText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  continueButton: {
    marginTop: theme.spacing(12),
    background: '#66b574',
    color: 'black',
    width: '140px',
    height: '48px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  watermarkTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  watermarkDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  watermarkGridItem: {
    marginBottom: theme.spacing(3),
  },
  labelText: {
    marginTop: theme.spacing(3),
  },
}))

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.continueButton}
      >
        Continue
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        className={classes.popupRoot}
      >
        <DialogTitle
          style={{ fontWeight: 500 }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          URLs for browser source
        </DialogTitle>

        <DialogContent dividers>
          <Box mt={2} mb={4}>
            <Typography className={classes.supportingText}>
              We sometimes show watermarks after popups.
            </Typography>

            <Typography className={classes.supportingText}>
              For more information about watermarks,{' '}
              <Link href="/watermark-info" target="_blank">
                {' '}
                click here
              </Link>
              .
            </Typography>
            {false && (
              <Typography className={classes.supportingText}>
                You can{' '}
                <Link href="/pricing" target="_blank">
                  {' '}
                  upgrade here
                </Link>
                .
              </Typography>
            )}
          </Box>
          <Grid container alignItems="flex-start">
            <Grid
              item
              key={1}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.watermarkGridItem}
            >
              <Typography>
                Your quota (
                <Link href="/pricing" target="_blank">
                  {getStoredJWTCookieData('userLevel') === 0 ? 'free' : ''}
                  {getStoredJWTCookieData('userLevel') === 1 ? 'basic' : ''}
                  {getStoredJWTCookieData('userLevel') === 2 ? 'pro' : ''} plan)
                </Link>
              </Typography>
              <Typography variant="h6">
                {getStoredJWTCookieData('userModuleUsesMonth')}/
                {getStoredJWTCookieData('userModuleUsesLimitMonth')}
              </Typography>
            </Grid>
            <Grid
              item
              key={2}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.watermarkGridItem}
            >
              <Typography>This popup uses</Typography>
              <Typography variant="h6">{props.cost} quota</Typography>
            </Grid>
            <Grid
              item
              key={3}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.watermarkGridItem}
            >
              <Typography>Under monthly quota</Typography>
              <Typography variant="h6">
                {getStoredJWTCookieData('userLevel') === 0 ? '0% chance' : ''}
                {getStoredJWTCookieData('userLevel') === 1 ? '0% chance' : ''}
                {getStoredJWTCookieData('userLevel') === 2 ? '0% chance' : ''}
              </Typography>
            </Grid>
            <Grid
              item
              key={4}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              className={classes.watermarkGridItem}
            >
              <Typography>Over monthly quota</Typography>
              <Typography variant="h6">
                {getStoredJWTCookieData('userLevel') === 0 ? '10% chance' : ''}
                {getStoredJWTCookieData('userLevel') === 1 ? '6% chance' : ''}
                {getStoredJWTCookieData('userLevel') === 2 ? '6% chance' : ''}
              </Typography>
            </Grid>
          </Grid>

          <Divider className={classes.watermarkDivider} />
          {false && (
            <Typography variant="h6" className={classes.watermarkTitle}>
              Chance of watermark
            </Typography>
          )}
          {false && (
            <Typography className={classes.supportingText}>
              <b>Tip:</b> Paste URL into a <b>60fps</b> browser source for
              smooth animations
            </Typography>
          )}
          {false && (
            <Typography className={classes.supportingText}>
              Resize by changing resolution, <b>do not</b> drag corners
            </Typography>
          )}
          {false && (
            <Typography className={classes.supportingText}>
              <b>Tip:</b> To test your layout, change to loop=yes at the end of
              the URL. This makes it ignore the animation delays.)
            </Typography>
          )}
          <Typography className={classes.labelText}>
            Looping animation for alignment in OBS:
          </Typography>
          {true && (
            <TextField
              className={classes.urlOutputField}
              id="outputURL"
              placeholder="Placeholder"
              helperText=""
              margin="normal"
              value={props.outputUrl + '&loop=yes'}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
            />
          )}
          <Typography className={classes.labelText}>
            Your animation - <b>use 60fps</b>:
          </Typography>
          {true && (
            <TextField
              className={classes.urlOutputField}
              id="outputURL2"
              placeholder="Placeholder"
              helperText=""
              margin="normal"
              value={props.outputUrl + '&loop=no'}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CopyToClipboard text={props.outputUrl + '&loop=yes'}>
            <Button>
              <b
                style={{
                  fontWeight: 500,
                  paddingLeft: '12px',
                  paddingRight: '12px',
                }}
              >
                Copy looping URL
              </b>
            </Button>
          </CopyToClipboard>
          <CopyToClipboard text={props.outputUrl + '&loop=no'}>
            <Button>
              <b
                style={{
                  fontWeight: 500,
                  paddingLeft: '12px',
                  paddingRight: '12px',
                }}
              >
                Copy final URL
              </b>
            </Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    </div>
  )
}
