import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

/* Usage example:

  <NumberFieldOption
    element="e1"
    optionName="animationDelay"
    parentHandleUpdate={props.alertParentOptionChange}
    title="Minutes before first popup"
    defaultValue="10"
  />
*/

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: '#f1f1f1',
  },
  labelText: {
    textAlign: 'left',
  },
}))

export default function NumberFieldOption(props) {
  const classes = useStyles()

  const [choice, setChoice] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)

  console.log('choice', choice)

  const handleChange = (name) => (event) => {
    setChoice(event.target.value)

    //update url in parent
    //TODO check if event.target.value is needed (setChoice is synchronous, so that choice can be used instead)
    setValueChanged(true)
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      event.target.value
    )
  }

  if (!valueChanged) {
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      props.defaultValue
    )
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.labelText}>{props.title}</Typography>
          <TextField
            className={classes.field}
            variant="outlined"
            size="small"
            id="standard-number"
            value={choice}
            onChange={handleChange()}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}
