import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { getCookie, setCookie } from '../common/cookie'

const styles = {
  root: {
    background: 'white',
    position: 'fixed',
    bottom: '2vw',
    right: '2vw',
    border: '1px solid rgba(0, 0, 0, .12)',
    zIndex: '100000',
  },
  root2: {
    background: '#66b574',
    borderRadius: 3,
    border: 0,
    color: 'black',
    height: 48,
    padding: '0 30px',
    margin: '0.5vw',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    fontSize: '0.8rem',
  },
}

function PopupDashboardHelp(props) {
  const { classes, children, className, ...other } = props

  const [hasAccepted, setHasAccepted] = React.useState(
    getCookie('gotDashboardHelp') === 'yes'
  )

  const asd = () => {
    setCookie('gotDashboardHelp', 'yes', 365 * 10)
    setHasAccepted(true)
  }

  return (
    <span>
      {!hasAccepted && (
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item key={1} xs={8} xl={8}>
              <p>Click any animation to get started</p>
            </Grid>
            <Grid item key={2} xs={4} xl={4}>
              <Button className={classes.root2} onClick={asd}>
                OK
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </span>
  )
}

PopupDashboardHelp.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default withStyles(styles)(PopupDashboardHelp)
