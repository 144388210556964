import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
//import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Copyright from '../components/common/Copyright'
import SignUpPolicyAgreement from '../components/common/SignUpPolicyAgreement'

import standardTheme from '../common/standardTheme'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    marginTop: theme.spacing(3),
  },
  card: {
    padding: '2vw',
    marginTop: '5vw',
    marginLeft: '3vw',
    marginRight: '3vw',
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  container: {
    padding: theme.spacing(0),
  },
}))

export default function SignUp() {
  const classes = useStyles()

  let url_string = window.location.href //window.location.href
  let url = new URL(url_string)
  let error = url.searchParams.get('error')
  let username = url.searchParams.get('username')
  let email = url.searchParams.get('email')

  window.dataLayer = window.dataLayer || []
  return (
    <ThemeProvider theme={standardTheme}>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-466241594"
      ></script>
      <script></script>

      <Container className={classes.container} component="main" maxWidth="sm">
        <Card className={classes.card}>
          <CssBaseline />
          <div className={classes.paper}>
            {true && (
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            )}
            <Typography style={{ fontWeight: 700 }} component="h1" variant="h5">
              Sign up
            </Typography>
            {error && error === 'invalidpasswordmatch' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Passwords must match
              </Typography>
            )}
            {error && error === 'invalidusername' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Invalid username, must only contain numbers and letters
              </Typography>
            )}

            {error && error === 'invalidemail' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Must be a valid email
              </Typography>
            )}

            {error && error === 'emptyfields' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                All fields must be filled
              </Typography>
            )}

            {error && error === 'sqlerror' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                There was an error processing your request
              </Typography>
            )}

            {error && error === 'usernametaken' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Username is taken
              </Typography>
            )}

            {error && error === 'emailtaken' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Email already in use
              </Typography>
            )}

            {error && error === 'passwordtooshort' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                The password must be atleast 8 characters long
              </Typography>
            )}

            <form
              className={classes.form}
              action="/backend/includes/signup.php"
              method="post"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    autoComplete="fname"
                    name="username"
                    variant="outlined"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    autoFocus
                    defaultValue={username ? username : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    type="email"
                    defaultValue={email ? email : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="pwd"
                    label="Password"
                    id="pwd"
                    autoComplete="current-password"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="pwd-repeat"
                    label="Confirm password"
                    id="pwd-repeat"
                    autoComplete="current-password"
                    type="password"
                  />
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                name="signup-submit"
                className={classes.submit}
                onClick={() => {
                  window.gtag('config', 'AW-466241594')
                  window.gtag('event', 'conversion', {
                    send_to: 'AW-466241594/i2SkCLeF5doCELqQqd4B',
                  })
                  /*function gtag_report_conversion(url) {
                    console.log('reporting conversion')
                    var callback = function () {
                      if (typeof url != 'undefined') {
                        window.location = url
                      }
                    }
                    window.dataLayer.push(
                      ('event',
                      'conversion',
                      {
                        send_to: 'AW-466241594/i2SkCLeF5doCELqQqd4B',
                        event_callback: callback,
                      })
                    )
                    return false
                  }
                  gtag_report_conversion('/signup')*/
                }}
              >
                Sign Up
              </Button>
              <Grid container justify="center">
                <Grid item>
                  <Link href="/signin" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Card>
        <Box mt={4} mr={6} ml={6}>
          <SignUpPolicyAgreement />
        </Box>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  )
}
