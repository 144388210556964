import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

export default function SignUpPolicyAgreement() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      By creating an account, you acknowledge that you accept the use of
      cookies. You also acknowledge that you have read and agreed to the{' '}
      <Link
        color="inherit"
        style={{ textDecoration: 'underline' }}
        href="https://app.termly.io/document/terms-and-conditions/0431793e-ff35-4588-bad2-4717393e9ab3"
      >
        terms and conditions
      </Link>
      {'.'}
    </Typography>
  )
}
