import React, { Fragment } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Copyright from '../components/common/Copyright'
import WindowFocusHandler from '../components/WindowFocusHandler'

import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import DisplayCard from '../components/DisplayCard2'
import PopupDashboardHelp from '../components/PopupDashboardHelp'

import standardTheme from '../common/standardTheme'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    scrollBehavior: 'smooth',
    background: '#c31432' /* fallback for old browsers */,
    background: 'linear-gradient(315deg, #f4f4f4, #eeeeee);',

    color: 'black',

    background: 'red',
    backgroundColor: '#f4f4f4',
    //backgroundImage: "linear-gradient(30deg, #efefef 12%, transparent 12.5%, transparent 87%, #efefef 87.5%, #efefef), linear-gradient(150deg, #efefef 12%, transparent 12.5%, transparent 87%, #efefef 87.5%, #efefef), linear-gradient(30deg, #efefef 12%, transparent 12.5%, transparent 87%, #efefef 87.5%, #efefef), linear-gradient(150deg, #efefef 12%, transparent 12.5%, transparent 87%, #efefef 87.5%, #efefef), linear-gradient(60deg, #fafafa 25%, transparent 25.5%, transparent 75%, #fafafa 75%, #fafafa), linear-gradient(60deg, #fafafa 25%, transparent 25.5%, transparent 75%, #fafafa 75%, #fafafa)",
    background: 'linear-gradient(310deg, #fdfffb 0%, #efefee 100%);',

    backgroundColor: '#f6f6f6',
    backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,

    //backgroundSize: "1000px 1750px",
    //backgroundPosition: "0 0, 0 0, 20px 35px, 2000px 35px, 0 0, 20px 35px;",

    //clipPath: "circle(50px at 0 100px)",

    /*

    background-color: #f7f7f7;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23ededed' stroke-width='4.9'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23f7f5f7'%3E%3Ccircle cx='769' cy='229' r='10'/%3E%3Ccircle cx='539' cy='269' r='10'/%3E%3Ccircle cx='603' cy='493' r='10'/%3E%3Ccircle cx='731' cy='737' r='10'/%3E%3Ccircle cx='520' cy='660' r='10'/%3E%3Ccircle cx='309' cy='538' r='10'/%3E%3Ccircle cx='295' cy='764' r='10'/%3E%3Ccircle cx='40' cy='599' r='10'/%3E%3Ccircle cx='102' cy='382' r='10'/%3E%3Ccircle cx='127' cy='80' r='10'/%3E%3Ccircle cx='370' cy='105' r='10'/%3E%3Ccircle cx='578' cy='42' r='10'/%3E%3Ccircle cx='237' cy='261' r='10'/%3E%3Ccircle cx='390' cy='382' r='10'/%3E%3C/g%3E%3C/svg%3E");

//wavey
background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23fcfcfc' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23f9f9f9' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23f6f6f6' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23f3f3f3' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23f0f0f0' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23ededed' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ebebeb' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23e8e8e8' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23e6e6e6' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23e3e3e3' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;

//go to? darker background lighter lines
background-color: #f6f6f6;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='536' height='536' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='23'/%3E%3Ccircle cx='539' cy='269' r='23'/%3E%3Ccircle cx='603' cy='493' r='23'/%3E%3Ccircle cx='731' cy='737' r='23'/%3E%3Ccircle cx='520' cy='660' r='23'/%3E%3Ccircle cx='309' cy='538' r='23'/%3E%3Ccircle cx='295' cy='764' r='23'/%3E%3Ccircle cx='40' cy='599' r='23'/%3E%3Ccircle cx='102' cy='382' r='23'/%3E%3Ccircle cx='127' cy='80' r='23'/%3E%3Ccircle cx='370' cy='105' r='23'/%3E%3Ccircle cx='578' cy='42' r='23'/%3E%3Ccircle cx='237' cy='261' r='23'/%3E%3Ccircle cx='390' cy='382' r='23'/%3E%3C/g%3E%3C/svg%3E");

maybe this instead?
background-color: #f6f6f6;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E");


    */
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
    color: 'black',
    display: 'flex',
  },
  accountButton: {
    textAlign: 'right',
  },
  navToolbar: {
    width: '100%',
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  contentGrid: {
    justifyContent: 'left',
  },
  categoryTitle: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    textAlign: 'left',
    fontSize: '2.05rem',
    marginLeft: '20.8333%',
    '@media (max-width:1280px)': {
      marginLeft: '0%',
      textAlign: 'center',
    },
  },
  firstCategoryTitle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(6),
    textAlign: 'left',
    fontSize: '2.05rem',
    marginLeft: '20.8333%',
    '@media (max-width:1280px)': {
      marginLeft: '0%',
      textAlign: 'center',
    },
  },
  categoryDivider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(5),
    height: '2px',
    background: '#ddd',
  },
  navBarItem: {
    fontSize: '1rem',
  },
  navBarItemRight: {
    fontSize: '1rem',
    marginLeft: theme.spacing(2),
    marginTop: '3px',
  },
  content: {
    flexGrow: 1,
    paddingLeft: '10vw',
    paddingRight: '10vw',
  },
  toolbar2: {
    maxHeight: '20px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  toolbarItem: {
    color: 'black',
    marginLeft: theme.spacing(3),
  },
  toolbarItem2: {
    color: 'black',
    marginLeft: theme.spacing(6),
  },
  firstCategoryDivider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(5),
  },
  searchRoot: {
    //position: "fixed",
    //top: "90px",
    //right: "20px",
    display: 'flex',
    justifyContent: 'center',
    padding: '2px 8px',
    display: 'flex',
    alignItems: 'center',
    width: 350,
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(8),
    boxShadow: `0px 0px 2px 0px rgba(0,0,0,12)`,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 6,
  },
  toolbarDivider: {
    height: 28,
    margin: 4,
  },
  animationCategory: {
    margin: theme.spacing(2),
  },
  firstAnimationCategory: {},
  toolbar: theme.mixins.toolbar,
}))

export default function Dashboard() {
  updateToken()
  const classes = useStyles()

  function getOriginDomain() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:8000'
    }
    return window.location.origin
  }

  function getOriginDomainFront() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:3000'
    }
    return window.location.origin
  }

  function updateToken() {
    const Http = new XMLHttpRequest()
    const url = getOriginDomain() + '/backend/updateToken.php'

    Http.open('POST', url)
    Http.send()
  }

  return (
    <ThemeProvider theme={standardTheme}>
      <div className={classes.root}>
        <WindowFocusHandler></WindowFocusHandler>
        <PopupDashboardHelp />
        <CssBaseline />
        <div
          style={{
            background: '#66b574',
            height: '4px',
            width: '100%',
            position: 'fixed',
            zIndex: '1202',
            left: '0',
          }}
        />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar2}>
            <Typography variant="h6">StreamZombie</Typography>
            {false && (
              <Paper component="form" className={classes.searchRoot}>
                <InputBase
                  className={classes.input}
                  placeholder="Search coming soon"
                  inputProps={{ 'aria-label': 'search google maps' }}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )}

            <div className={classes.navToolbar}>
              <Link href="/contact" className={classes.toolbarItem}>
                <Typography className={classes.navBarItem}>Contact</Typography>
              </Link>
              <Link href="/pricing" className={classes.toolbarItem}>
                <Typography className={classes.navBarItem}>Pricing</Typography>
              </Link>
              <Link href="/account" className={classes.toolbarItem}>
                <Typography className={classes.navBarItem}>Account</Typography>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={4} className={classes.contentGrid}>
            <Grid
              item
              xs={12}
              xl={12}
              className={classes.firstAnimationCategory}
              id="Youtube"
            >
              <Divider
                light={true}
                variant="fullWidth"
                className={classes.firstCategoryDivider}
              />
              <Typography variant="h3" className={classes.firstCategoryTitle}>
                YouTube popup animations
              </Typography>
            </Grid>

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/latestVideo.php?e2_videoPageLink=UC-R9KsHOOphWhr8pxkbsN1A&e1_relevanceLimit=14&e1_animationDuration=12&e1_animationInterval=30&e1_animationDelay=10&e1_animation=instant&b1_animation=circlePop&c1_textColor=000000&b1_include=1&b1_backgroundColor=ffffff&b1_width=90&b1_height=90&b1_borderR=4&b1_opacity=100&e1_text=Latest YouTube !video&e1_fontSize=8.1&e1_font=Open Sans Condensed, sans-serif&e2_width=70&e3_fontSize=4.5&e3_width=70&e3_font=Open Sans Condensed, sans-serif&b1_includeBgShadow=1&loop=yes'
              }
              header="Showcase latest YouTube video"
              //body="Every time it the animation is loaded it looks for your latest video and gets all the relevant information to show the animation. There is also an option to automatically disable the popup if you have not uploaded in a while."
              body="Automatically shows your latest video. Can also be automatically disabled if you have not uploaded in a while."
              link="/latestVideo"
              quotaCost={8}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/playlistVideo.php?e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&e2_playlistID=youtube.com/playlist?list=PLAyCLklRAGVQMfBHBIg5PeJl1qHlHROVU&e1_text=Video from playlist&e1_fontSize=9&e2_width=65&e3_fontSize=4.5&e3_width=65&loop=yes'
              }
              header="Showcase last video of a YouTube playlist"
              body="Showcases the Youtube video that is last in a playlist. Just enter a link to your unlisted/public YouTube playlist."
              link="/playlistVideo"
              quotaCost={8}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/video.php?e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&e2_videoID=www.youtube.com/watch?v=kJQP7kiw5Fk&e1_text=Watch this video&e1_fontSize=9&e2_width=65&e3_fontSize=4.5&e3_width=65&loop=yes'
              }
              header="Specific Youtube video"
              body="Showcase a specific video you want people to know about."
              link="/video"
              quotaCost={8}
            />

            {true && (
              <Fragment>
                <Grid item key={1} xs={12} xl={12} id="Images">
                  {true && (
                    <Divider
                      light={true}
                      variant="fullwidth"
                      className={classes.categoryDivider}
                    />
                  )}

                  <Typography variant="h5" className={classes.categoryTitle}>
                    Image/sponsor animations (BETA)
                  </Typography>
                </Grid>

                <DisplayCard
                  data={
                    getOriginDomain() +
                    '/animation/imageWithText.php?e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&i1_path=&undefined_undefined=undefined&i1_width=70&b1_include=0&e1_textColor=000000&e1_text=Some text here&e1_fontSize=9&e1_font=Arial, sans-serif&e1_width=70&loop=yes'
                  }
                  header="Image"
                  body="Upload an image and animate it. It could for example be for brand deal or sponsor."
                  link="/imageWithText"
                  quotaCost={4}
                />
              </Fragment>
            )}

            <Grid
              item
              key={3}
              xs={12}
              xl={12}
              className={classes.animationCategory}
              id="Socials"
            >
              {true && (
                <Divider
                  light={true}
                  variant="fullwidth"
                  className={classes.categoryDivider}
                />
              )}
              <Typography variant="h3" className={classes.categoryTitle}>
                Social media (BETA)
              </Typography>
            </Grid>

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/socials.php?e1_animationInterval=30&e1_animationDelay=10&e1_animationDuration=8&e2_width=20&f1_fontSize=8&f1_textColor=000000&e1_animation=fade&f1_font=Open Sans Condensed, sans-serif&b1_backgroundColor=ffffff&b1_width=60&b1_opacity=100&e2_include=1&e2_text=streamzombie_&e3_include=1&e3_text=streamzombie&e4_include=1&e4_text=streamzombie&b1_includeBgShadow=1&e0_includeIconShadow=1&loop=yes'
              }
              header="Social media square"
              body="Showcase your different social media channels."
              link="/socials"
              quotaCost={2}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/socialsRound.php?e1_animationInterval=30&e1_animationDelay=10&e1_animationDuration=8&e2_width=18.75&f1_fontSize=9.450000000000001&f1_textColor=000000&e1_animation=fade&f1_font=Open Sans Condensed, sans-serif&b1_backgroundColor=ffffff&b1_width=70&b1_borderR=50&b1_opacity=100&e2_include=1&e2_text=streamzombie_&e3_include=1&e3_text=streamzombie&e4_include=1&e4_text=streamzombie&b1_includeBgShadow=1&loop=yes'
              }
              header="Social media round"
              body="Showcase your different social media channels."
              link="/socialsRound"
              quotaCost={2}
            />

            <Grid
              item
              key={3}
              xs={12}
              xl={12}
              className={classes.animationCategory}
              id="Twitch"
            >
              {true && (
                <Divider
                  light={true}
                  variant="fullwidth"
                  className={classes.categoryDivider}
                />
              )}
              <Typography variant="h3" className={classes.categoryTitle}>
                Twitch popup animations
              </Typography>
            </Grid>

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/primeGaming.php?e1_text=Subscribe for free with&e1_fontSize=9&e1_animation=zoom&e1_animationDuration=10&e1_delay=0&e2_width=60&e2_animation=fade&e2_animationDuration=8&e2_delay=1&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
              }
              header="Prime gaming"
              body="If you want people to know or be reminded that they can use Prime gaming."
              link="/primeGaming"
              quotaCost={2}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/twitchPrime.php?e1_text=Subscribe for free!&e1_fontSize=9&e1_animation=zoom&e1_animationDuration=10&e1_delay=0&e2_width=48&e2_animation=zoom&e2_animationDuration=8&e2_delay=1&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
              }
              header="Twitch prime (old)"
              body="If you want people to know or be reminded that they can use Twitch Prime."
              link="/twitchPrime"
              quotaCost={2}
            />

            {true && (
              <DisplayCard
                data={
                  getOriginDomain() +
                  '/animation/twitchPrimeModern.php?e1_animationInterval=30&e1_animationDelay=10&e1_textColor=000000&e1_text=Subscribe for free!&e1_fontSize=9&e1_font=Open Sans Condensed, sans-serif&e1_animation=zoom&e1_animationDuration=10&e1_delay=0&e2_fill=9147ff&e2_width=40&e2_animation=zoomClick&e2_animationDuration=8&e2_delay=1&loop=yes'
                }
                header="Twitch prime variant (old)"
                body="If you want people to know or be reminded that they can use Twitch Prime."
                link="/twitchPrimeModern"
                quotaCost={2}
              />
            )}

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/followOld.php?e1_text=Follow the stream!&e1_fontSize=9&e1_animation=zoom&e1_animationDuration=10&e1_delay=0&e2_width=40&e2_animation=zoomClick&e2_animationDuration=8&e2_delay=1&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
              }
              header="Follow"
              body="If people like what they see, the likely want to hit that follow button, sometimes they forget though."
              link="/followOld"
              quotaCost={2}
            />
            <Grid
              item
              key={12}
              xs={12}
              xl={12}
              className={classes.animationCategory}
              id="Other"
            >
              {true && (
                <Divider
                  light={true}
                  variant="fullwidth"
                  className={classes.categoryDivider}
                />
              )}
              <Typography variant="h5" className={classes.categoryTitle}>
                Other popup animations
              </Typography>
            </Grid>

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/external.php?e1_source=' +
                getOriginDomainFront() +
                '/example-of-external-url2&e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
              }
              header="From another website"
              body="This can be any link from another website. You might only want to show a donation goal every 15 minutes, for example. "
              link="/external"
              quotaCost={1}
            />

            <Grid item key={1} xs={12} xl={12} id="Text">
              {true && (
                <Divider
                  light={true}
                  variant="fullwidth"
                  className={classes.categoryDivider}
                />
              )}

              <Typography variant="h5" className={classes.categoryTitle}>
                Text popup animations
              </Typography>
            </Grid>

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/text.php?e1_text=Some text here&e1_animationDuration=10&e1_fontSize=9&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
              }
              header="Single text"
              body="Simple text, stating something that people should know about"
              link="/text"
              quotaCost={1}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/doubleText.php?e2_text=Some text here&e1_animationDuration=10&e2_fontSize=9&e3_text=Some other text&e3_fontSize=6.3&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&e3_textColor=609732&loop=yes'
              }
              header="Double text"
              body="Two texts, in case you want different sizes or colors"
              link="/doubleText"
              quotaCost={1}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/tripleText.php?e2_text=Some text here&e1_animationDuration=10&e2_fontSize=8.1&e3_text=Some other text&e3_fontSize=5.175&e4_text=Something else&e4_fontSize=9&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&e4_textColor=609732&e3_textColor=f000f0&loop=yes'
              }
              header="Triple text"
              body="Three texts, in case you want to make something super fancy"
              link="/tripleText"
              quotaCost={1}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/separatedDoubleText.php?e1_text=Some animated text&e1_fontSize=9&e1_animation=swipeDown&e1_animationDuration=10&e1_delay=0&e2_text=Seperate animated text&e2_fontSize=6.75&e2_animation=zoom&e2_animationDuration=6&e2_delay=2&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
              }
              header="Separated double text"
              body="The different texts can have different animations"
              link="/separatedDoubleText"
              quotaCost={1}
            />

            <Grid
              item
              key={11}
              xs={12}
              xl={12}
              className={classes.animationCategory}
              id="Time"
            >
              {true && (
                <Divider
                  light={true}
                  variant="fullwidth"
                  className={classes.categoryDivider}
                />
              )}
              <Typography variant="h5" className={classes.categoryTitle}>
                Timers
              </Typography>
            </Grid>

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/countdownTimerWithText.php?e3_text=Eating pizza in&e3_fontSize=14&e1_d=0&e1_h=2&e1_m=0&e1_s=0&e1_textWhenDone=&e1_fontSize=12&e2_fontSize=6'
              }
              header="Countdown with text"
              body="Countdown to something you want people to know about"
              link="/countdownTimerWithText"
              quotaCost={0}
            />

            <DisplayCard
              data={
                getOriginDomain() +
                '/animation/countdownTimer.php?e1_d=0&e1_h=1&e1_m=12&e1_s=0&e1_textWhenDone=&e1_fontSize=14&e2_fontSize=7&e2_text=Some text here&e2_textColor=000000&e1_textColor=000000'
              }
              header="Countdown"
              body="Countdown to something you want people to know about"
              link="/countdownTimer"
              quotaCost={0}
            />

            <Box mt={6} mb={0}>
              {true && (
                <Divider
                  light={true}
                  variant="fullwidth"
                  className={classes.categoryDivider}
                />
              )}
            </Box>
          </Grid>
          <Box mt={6} mb={6}>
            <Copyright />
          </Box>
        </main>
      </div>
    </ThemeProvider>
  )
}
