import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from './CollapsableInputCategory'
import SelectOption from './SelectOption'
import NumberFieldOption from './NumberFieldOption'
import SliderOption from './SliderOption'

let outputOptions = []

function handleOptionChange(option, value) {
  outputOptions[option] = value
  console.log(outputOptions)
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))

export default function AnimationInput() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="Text settings">
        <SelectOption
          element="em1"
          optionName="animation"
          parentHandleUpdate={handleOptionChange}
          title="Animation"
          options={[
            ['zoom', 'Zoom'],
            ['zoomClick', 'Zoom click'],
            ['fade', 'Fade'],
            ['swipeRight', 'Swipe right'],
            ['swipeLeft', 'Swipe left'],
            ['swipeUp', 'Swipe up'],
            ['swipeDown', 'Swipe down'],
            ['instant', 'Instant'],
          ]}
          defaultValue="zoom"
        />
        <NumberFieldOption
          element="em2"
          optionName="animationInterval"
          parentHandleUpdate={handleOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="em2"
          optionName="animationDelay"
          parentHandleUpdate={handleOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
        <SliderOption
          element="em2"
          optionName="textSize"
          parentHandleUpdate={handleOptionChange}
          title="Text size"
          defaultValue={20}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
        />
      </CollapsableInputCategory>
    </div>
  )
}
