import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { getAccessToken } from '../../common/cookie'

export default function RedirectOnNotSignedIn(props) {
  // true -> ignore redirect if in dev environment (localhost)
  const IGNORE_DEV_ENVIRONMENT = true

  function checkLogin() {
    if (!IGNORE_DEV_ENVIRONMENT || window.location.hostname !== 'localhost') {
      if (getAccessToken() == '') {
        window.location.replace(props.to)
        //window.location.replace('/');
      }
    }
  }

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {checkLogin()}
    </Typography>
  )
}
