import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
//import Button from '../components/Button';
//import Button from '@material-ui/core/Button';
//import HowItWorksButton from './HowItWorksButton';
//import Typography from '../components/Typography';
//import Typography from '@material-ui/core/Typography';
import HowItWorksTypography from './HowItWorksTypography'

const styles = (theme) => ({
  root: {
    display: 'flex',
    //backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  item2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'right',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
})

function ProductHowItWorks(props) {
  const { classes } = props

  return (
    <Container className={classes.container}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <div className={classes.item}>
            <div className={classes.number}>1.</div>
            <HowItWorksTypography variant="h5" align="center">
              Select an animation you like
            </HowItWorksTypography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.item2}>
            <div className={classes.number}>2.</div>
            <HowItWorksTypography variant="h5" align="center">
              Choose settings that fits you
            </HowItWorksTypography>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.item}>
            <div className={classes.number}>3.</div>
            <HowItWorksTypography variant="h5" align="center">
              Paste into OBS, all done!
            </HowItWorksTypography>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProductHowItWorks)
