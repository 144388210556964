import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 200,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  labelText: {
    textAlign: 'left',
  },
}))

export default function SliderOption(props) {
  const classes = useStyles()

  const [value, setValue] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)

  const handleSliderChange = (event, newValue) => {
    setValueChanged(true)

    if (props.multiplier !== undefined) {
      setValue(newValue * props.multiplier)
    }

    setValue(newValue)
  }

  const handleSliderChangeCommitted = (event, newValue) => {
    if (props.multiplier !== undefined) {
      newValue *= props.multiplier
    }

    props.parentHandleUpdate(props.element + '_' + props.optionName, newValue)
  }

  if (!valueChanged) {
    if (props.multiplier !== undefined) {
      props.parentHandleUpdate(
        props.element + '_' + props.optionName,
        props.defaultValue * props.multiplier
      )
    } else {
      props.parentHandleUpdate(
        props.element + '_' + props.optionName,
        props.defaultValue
      )
    }
  }

  const marks = [
    {
      value: props.min,
      label: props.minText,
    },
    {
      value: props.max,
      label: props.maxText,
    },
  ]

  function valuetext(value) {
    // TODO maybe round this when stepSize is not integer
    return `${value}`
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.labelText}>{props.title}</Typography>
          <Slider
            defaultValue={2}
            value={typeof value === 'number' ? value : 0}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-custom"
            step={props.stepSize}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
            valueLabelDisplay="auto"
            marks={marks}
            min={props.min}
            max={props.max}
          />
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}

/* Usage example:

<SliderOption
  element="em2"
  optionName="textSize"
  parentHandleUpdate={props.alertParentOptionChange}
  title="Text size"
  defaultValue={20}
  stepSize={1}
  min={0}
  max={100}
  minText="0%"
  maxText="100%"
/>
*/
