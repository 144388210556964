import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CenterFocusStrong } from '@material-ui/icons'

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: '#f1f1f1',
  },
  labelText: {
    textAlign: 'left',
  },
  uploadedStyle: {},
  notUploadedStyle: {
    //display: 'none',
  },
  confirmUploadButton: {
    marginTop: '4px',
    marginBottom: '1px',
    background: '#66b574',
  },
  uploadInput: {
    float: 'left',
    marginTop: '1.2em',
    marginBottom: '1.2em',
  },
  uploadButton: {
    marginTop: theme.spacing(1),
  },
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function UploadFile(props) {
  const classes = useStyles()

  const [choice, setChoice] = React.useState(props.defaultValue)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [valueChanged, setValueChanged] = React.useState(false)

  const [hasUploaded, setHasUploaded] = React.useState(false)

  const [uc, setuc] = React.useState(classes.notUploadedStyle)

  function onChange(target) {
    console.log('changing')
    setHasUploaded(true)
    setuc(classes.uploadedStyle)
  }

  const handleChange = (name) => (event) => {
    setChoice(event.target.value)

    //update url in parent
    //TODO check if event.target.value is needed (setChoice is synchronous, so that choice can be used instead)
    setHasUploaded(true)
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      event.target.value
    )
  }

  if (!valueChanged) {
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      props.defaultValue
    )
  }

  const reload = () => {

  }

  const upLoadButtonClick = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.labelText}>{props.title}</Typography>

          <Dialog
            onClose={handleClose}
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="customized-dialog-title"
          >
            <DialogTitle id="customized-dialog-title"
              onClose={handleClose} >
              Reload page
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                If you added any new image, please reload this page to see them.
              </Typography>
            </DialogContent>

            <DialogActions>
              <Link href="/imageWithText">

                <Button>
                  <b
                    style={{
                      alignSelf: "center",
                      fontWeight: 500,
                      paddingLeft: '12px',
                      paddingRight: '12px',
                    }}
                  >
                    Reload
              </b>
                </Button>
              </Link>
            </DialogActions>
          </Dialog>



          <Link color="inherit" href="/upload_image" target="_blank">
            <Button
              variant="contained"
              component="span"
              size="default"
              className={classes.uploadButton}
              onClick={upLoadButtonClick}
            >
              Upload a new image
              <BackupOutlinedIcon
                style={{ marginLeft: '0.5em' }}
                fontSize="small"
              />
            </Button>
          </Link>
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}
