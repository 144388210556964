import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

/* Usage example:

  <NumberFieldOption
    element="em1"
    optionName="animationDelay"
    parentHandleUpdate={props.alertParentOptionChange}
    title="Minutes before first popup"
    defaultValue="10"
  />
*/

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: '#f1f1f1',
  },
  labelText: {
    textAlign: 'left',
  },
}))

export default function TextOption(props) {
  const classes = useStyles()

  const [choice, setChoice] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)
  const [xlWidth, setXlWidth] = React.useState(props.size == 'double' ? 8 : 4)
  const [lgWidth, setLgWidth] = React.useState(props.size == 'double' ? 12 : 6)

  const [debouncedChoice, setDebouncedChoice] = useDebounce(choice, 500)

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value)
        }, delay)

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler)
        }
      },
      [value, delay] // Only re-call effect if value or delay changes
    )

    return debouncedValue
  }

  useEffect(() => {
    console.log('debouncing')
    console.log('debouncedChoice:' + debouncedChoice)
    props.parentHandleUpdate(props.element + '_' + props.optionName, choice)
    console.log('deboun:' + choice)
  }, [debouncedChoice])

  const handleChange2 = (name) => (event) => {
    console.log('updating some stuff sdasdjlkj')
    setChoice(event.target.value)

    console.log('change2222222')
    //update url in parent
    //TODO check if event.target.value is needed (setChoice is synchronous, so that choice can be used instead)
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      event.target.value
    )
    console.log('change3333333')
    setValueChanged(true)
  }

  if (!valueChanged) {
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      props.defaultValue
    )
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={lgWidth} xl={xlWidth}>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.labelText}>{props.title}</Typography>
          <TextField
            className={classes.field}
            variant="outlined"
            size="small"
            value={choice}
            onChange={handleChange2()}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}
