import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/common/Copyright'
import Card from '@material-ui/core/Card'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import UpgradeButton from '../components/dashboard/UpgradeButton'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  root: {
    height: '100vw',
  },
  paper: {
    marginTop: theme.spacing(6),
  },
  accountCard: {
    marginTop: theme.spacing(8),
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backButton: {
    position: 'fixed',
    left: 24,
    top: 20,
    textAlign: 'left',
    color: 'black',
  },
  watermarkGridItem: {
    marginBottom: theme.spacing(1),
  },
  watermarkTable: {
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    marginLeft: '10%',
  },
  watermarkTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  animationDisplayItemDisplay: {
    background: 'red',
    // TODO fix this ratio
    width: '50%',
    height: '50%',
    margin: '10%',
    height: '80%',
  },
}))

export default function WaterMarkInfo() {
  const classes = useStyles()

  const [updateUrl, setUpdateUrl] = React.useState('')
  const [cancelUrl, setCancelUrl] = React.useState('')
  const [nextBillDate, setNextBillDate] = React.useState('')
  const [eventTime, setEventTime] = React.useState('')

  const polygonPop = keyframes`
    0% { clip-path:  polygon(50% 50%, 50% 25%, 50% 50%, 75% 50%, 50% 50%, 50% 75%, 50% 50%, 25% 50%); }
    5% { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
    95% { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
    100% { clip-path:  polygon(50% 50%, 50% 25%, 50% 50%, 75% 50%, 50% 50%, 50% 75%, 50% 50%, 25% 50%); }
  `

  const Animation1 = styled.div`
    animation: ${polygonPop} 10s 1s linear infinite;
  `

  function getOriginDomain() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:8000'
    }
    return window.location.origin
  }

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Link href="/dashboard">
          <Typography variant="h6" className={classes.backButton}>
            Go to dashboard
          </Typography>
        </Link>
        <Card mt={4} className={classes.accountCard}>
          <div className={classes.paper}>
            <Typography variant="h4">How watermarks work</Typography>

            <Typography>
              <br />
              We sometimes show watermarks after popups. <br />
              They are to promote this website, you can pay <br />
              to avoid them if you want.
              <br />
              <br />
              Depending on your plan, you have a set amount <br />
              of quota each month. Completely spending your <br />
              quota will increase the odds of a watermark. <br />
            </Typography>

            <object
              type="text/html"
              data={
                getOriginDomain() + '/animation/watermark/watermarkExample.html'
              }
              style={{ width: '76%', marginTop: '32px' }}
            ></object>
            <Typography>How watermark looks</Typography>

            {/* The odds for the backend is in $userAccess.php */}
            <Grid
              container
              className={classes.watermarkTable}
              alignItems="center"
            >
              <Grid item xs={12} className={classes.watermarkGridItem}>
                <Typography variant="h5" className={classes.watermarkTitle}>
                  Odds of getting a watermark
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1"></Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">Odds when under quota</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">Odds when over quota</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">Quota per month</Typography>
              </Grid>

              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">Free</Typography>
              </Grid>

              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">0%</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">6%</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">300</Typography>
              </Grid>

              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">Basic</Typography>
              </Grid>

              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">0%</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">5%</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">1,500</Typography>
              </Grid>

              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">Pro</Typography>
              </Grid>

              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">0%</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">5%</Typography>
              </Grid>
              <Grid item xs={3} className={classes.watermarkGridItem}>
                <Typography variant="body1">10,000</Typography>
              </Grid>
            </Grid>

            <UpgradeButton></UpgradeButton>
            {false && (
              <Box mb={4}>
                <Typography>
                  Note that these values might change in the future
                </Typography>
              </Box>
            )}

            <Grid
              container
              className={classes.watermarkTable}
              alignItems="center"
            >
              <Grid item xs={12} className={classes.watermarkGridItem}>
                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation1
                    className={classes.animationDisplayItemDisplay}
                  ></Animation1>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Card>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}
