import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

/* Usage example:

  <NumberFieldOption
    element="em1"
    optionName="animationDelay"
    parentHandleUpdate={props.alertParentOptionChange}
    title="Minutes before first popup"
    defaultValue="10"
  />
*/

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: '#f1f1f1',
  },
  labelText: {
    textAlign: 'left',
  },
}))

export default function CheckboxOption(props) {
  const classes = useStyles()

  const [choice, setChoice] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)
  const [xlWidth, setXlWidth] = React.useState(props.size == 'double' ? 8 : 4)
  const [lgWidth, setLgWidth] = React.useState(props.size == 'double' ? 12 : 6)

  const handleChange = (name) => (event) => {
    setChoice(event.target.checked)

    console.log('event value ' + event.target.checked)

    //TODO check if event.target.value is needed (setChoice is synchronous, so that choice can be used instead)
    setValueChanged(true)
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      event.target.checked
    )

    if (props.setFunction) props.setFunction(event.target.checked)
  }

  if (!valueChanged) {
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      props.defaultValue
    )
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={lgWidth} xl={xlWidth}>
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography className={classes.labelText}>{props.title}</Typography>
          <Checkbox
            defaultChecked={props.defaultValue === 1}
            color="default"
            onChange={handleChange()}
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}
