import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'

import CollapsableSidebarCategory from './CollapsableSidebarCategory'

import UpgradePrompt from './UpgradePrompt'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignSelf: 'flex-end',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    overflow: 'hidden',
    overflow: 'auto',
  },
  drawer: {
    display: 'flex',
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  upgradePrompt: {
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: '0px',
  },
  upgradeDivider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  sidebarDivider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  sidebarCategoryLink: {
    textAlign: 'left',
    clear: 'both',
    width: '100%',
    color: 'black',
  },
  sidebarCategoryItem: {
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar,
}))

export default function SideBar() {
  const classes = useStyles()

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <CollapsableSidebarCategory title="Text" href="/dashboard#Text">
        <Link className={classes.sidebarCategoryLink} href="/text">
          <div className={classes.sidebarCategoryItem}>Text</div>
        </Link>
        <Link className={classes.sidebarCategoryLink} href="/doubleText">
          <div className={classes.sidebarCategoryItem}>Double text</div>
        </Link>
        <Link className={classes.sidebarCategoryLink} href="/tripleText">
          <div className={classes.sidebarCategoryItem}>Triple text</div>
        </Link>
        <Link
          className={classes.sidebarCategoryLink}
          href="/separatedDoubleText"
        >
          <div className={classes.sidebarCategoryItem}>
            Seperated double text
          </div>
        </Link>
      </CollapsableSidebarCategory>
      <Divider className={classes.sidebarDivider} />

      <CollapsableSidebarCategory title="Time" href="/dashboard#Time">
        <Link
          className={classes.sidebarCategoryLink}
          href="/countdownTimerWithText"
        >
          <div className={classes.sidebarCategoryItem}>Countdown with text</div>
        </Link>
        <Link className={classes.sidebarCategoryLink} href="/countdownTimer">
          <div className={classes.sidebarCategoryItem}>Countdown</div>
        </Link>
      </CollapsableSidebarCategory>
      <Divider className={classes.sidebarDivider} />

      <CollapsableSidebarCategory title="Youtube" href="/dashboard#Youtube">
        <Link className={classes.sidebarCategoryLink} href="/latestVideo">
          <div className={classes.sidebarCategoryItem}>Latest video</div>
        </Link>
        <Link className={classes.sidebarCategoryLink} href="/playlistVideo">
          <div className={classes.sidebarCategoryItem}>Playlist video</div>
        </Link>

        <Link className={classes.sidebarCategoryLink} href="/video">
          <div className={classes.sidebarCategoryItem}>Video</div>
        </Link>
      </CollapsableSidebarCategory>
      <Divider className={classes.sidebarDivider} />

      <CollapsableSidebarCategory title="Twitch" href="/dashboard#Twitch">
        <Link className={classes.sidebarCategoryLink} href="/twitchPrime">
          <div className={classes.sidebarCategoryItem}>Twitch prime</div>
        </Link>
        <Link className={classes.sidebarCategoryLink} href="/followOld">
          <div className={classes.sidebarCategoryItem}>Follow</div>
        </Link>
      </CollapsableSidebarCategory>
      <Divider className={classes.sidebarDivider} />

      <div className={classes.upgradePrompt}></div>
    </Drawer>
  )
}
