import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  upgradeButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '130px',
    height: '40px',
    borderRadius: '5px',
  },
}))

function UpgradeButton(props) {
  const classes = useStyles()
  return (
    <div>
      <Link href="/pricing">
        <Fab
          variant="extended"
          size="large"
          color="primary"
          aria-label="add"
          className={classes.upgradeButton}
        >
          Upgrade
        </Fab>
      </Link>
    </div>
  )
}

export default UpgradeButton
