import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import AnimationOutput2 from './AnimationOutput2'

let outputOptions = []

function handleOptionChange(option, value) {
  outputOptions[option] = value
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  urlOutputField: {
    width: '70%',
  },
  supportingText: {
    marginTop: theme.spacing(1),
  },

  toUseTitle: {
    marginTop: theme.spacing(8),
  },
  previewTitle: {
    marginBottom: theme.spacing(1),
  },
  changeColorText: {
    //offset for invisible color input element
    position: 'absolute',
    left: '0',
    top: 0,
    width: '100%',
    height: '100%',
  },
  changeColorTextOld: {
    //offset for invisible color input element
    paddingLeft: '3px',
    position: 'relative',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(0),
    textAlign: 'right',
    fontSize: '0.875rem',
    zIndex: '1',
    cursor: 'pointer',
    //marginLeft: "15%",
    //marginRight: "15%",
    textAlign: 'center',
  },
  cardMedia: {
    //marginLeft: "15%",
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    //boxShadow: "0 0px 6px rgba(0,0,0,.2)",
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  aspectRationFixer: {
    position: 'relative',
    width: '100%',
    //paddingTop: '56.25%', // 16:9
    paddingBottom: '75.0%', // 4:3
  },
  outputPreview: {
    //background: "gold",
    height: '80%',
    //marginBottom: "-20%",
    marginBottom: theme.spacing(2),
  },
  colorRoot: {},
  colorInput: {
    opacity: 0,
    border: 'none',
    zIndex: '1000000',
    width: '0px',
    height: '0px',
  },
  whiteTransparentPattern: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundPosition: '0px 0px, 10px 10px',
    backgroundSize: '20px 20px',
    backgroundImage:
      'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, #fbfbfb 25%, #fbfbfb 75%, #eee 75%, #eee 100%);',
  },
  blackTransparentPattern: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'blue',
    backgroundPosition: '0px 0px, 10px 10px',
    backgroundSize: '20px 20px',
    backgroundImage:
      'linear-gradient(45deg, #1a1a1a 25%, transparent 25%, transparent 75%, #1a1a1a 75%, #1a1a1a 100%),linear-gradient(45deg, #1a1a1a 25%, #242424 25%, #242424 75%, #1a1a1a 75%, #1a1a1a 100%);',
  },
  previewBackgroundText: {
    marginBottom: theme.spacing(2),
  },
  customColorButton: {
    marginBottom: theme.spacing(0),
    paddingRight: theme.spacing(-1),
    marginTop: theme.spacing(1),
  },
  whiteTransparent: {
    backgroundPosition: '0px 0px, 10px 10px',
    backgroundSize: '20px 20px',
    backgroundImage:
      'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, #fbfbfb 25%, #fbfbfb 75%, #eee 75%, #eee 100%);',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  blackTransparent: {
    backgroundPosition: '0px 0px, 10px 10px',
    backgroundSize: '20px 20px',
    backgroundImage:
      'linear-gradient(45deg, #1a1a1a 25%, transparent 25%, transparent 75%, #1a1a1a 75%, #1a1a1a 100%),linear-gradient(45deg, #1a1a1a 25%, #212121 25%, #212121 75%, #1a1a1a 75%, #1a1a1a 100%);',
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: 'white',
  },
}))

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#151515',
    borderColor: '#151515',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button)

export default function AnimationInput(props) {
  const classes = useStyles()

  const [color, setColor] = React.useState('#ffffff')
  const [backgroundType, setBackgroundType] = React.useState(0)

  const handleChange = (name) => (event) => {
    // To update text below preview (showing the color hex code)
    setColor(event.target.value)

    // Url cant contain the # character (unable to GET request the color), the # is re-added in createClass.php
    let urlSafeColor = event.target.value
  }

  return (
    <div>
      <div className={classes.outputPreview}>
        <div className={classes.aspectRationFixer}>
          <object
            rel="preload"
            style={{ backgroundColor: color }}
            className={
              backgroundType === 0
                ? classes.whiteTransparentPattern
                : backgroundType === 1
                ? classes.blackTransparentPattern
                : classes.cardMedia
            }
            type="text/html"
            data={props.previewUrl}
          >
            This should show a preview, please reload the page.
          </object>
        </div>
      </div>

      {false && (
        <Typography>
          Preview background
          <br></br>
        </Typography>
      )}
      <CustomButton
        onClick={() => setBackgroundType(0)}
        className={classes.whiteTransparent}
      >
        Transparent light
      </CustomButton>
      <CustomButton
        onClick={() => setBackgroundType(1)}
        className={classes.blackTransparent}
      >
        Transparent dark
      </CustomButton>
      <CustomButton
        onClick={() => setBackgroundType(2)}
        className={classes.customColorButton}
        style={{ backgroundColor: color }}
      >
        Color background
        <span className={classes.colorRoot}>
          <label
            htmlFor="preview-color-change"
            variant="body1"
            className={classes.changeColorText}
          ></label>
          <input
            type="color"
            className={classes.colorInput}
            onChange={handleChange()}
            value={color}
            id="preview-color-change"
          />
        </span>
      </CustomButton>

      <AnimationOutput2 cost={props.cost} outputUrl={props.outputUrl} />

      {false && (
        <div>
          <Typography variant="h5" className={classes.toUseTitle}>
            To use
          </Typography>

          <Typography className={classes.supportingText}>
            Paste URL into a new (60 fps) browser source
          </Typography>
          <Typography className={classes.supportingText}>
            Resize by changing resolution, <b>do not</b> drag corners
          </Typography>

          <TextField
            className={classes.urlOutputField}
            id="outlined-full-width"
            label="Animation URL"
            placeholder="Placeholder"
            helperText="Copy this into an OBS browser source"
            margin="normal"
            value={props.outputUrl}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </div>
      )}
    </div>
  )
}
