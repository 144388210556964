import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from '../CollapsableInputCategory'
import SelectOption from '../SelectOption'
import SelectOptionFonts from '../SelectOptionFonts'
import NumberFieldOption from '../NumberFieldOption'
import SliderOption from '../SliderOption'
import ColorOption from '../ColorOption'
import TextOption from '../TextOption'
import { standardFontOptions } from '../AnimationOptions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

export default function AnimationInputExample(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="Countdown">
        <NumberFieldOption
          element="e1"
          optionName="d"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Days"
          defaultValue="0"
        />
        <NumberFieldOption
          element="e1"
          optionName="h"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Hours"
          defaultValue="2"
        />
        <NumberFieldOption
          element="e1"
          optionName="m"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes"
          defaultValue="0"
        />
        <NumberFieldOption
          element="e1"
          optionName="s"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Seconds"
          defaultValue="0"
        />
        <TextOption
          element="e1"
          optionName="textWhenDone"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text at completion"
          defaultValue=""
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Text">
        <SliderOption
          element="e1"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <SliderOption
          element="e2"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Seperator size"
          defaultValue={20}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />

        <ColorOption
          element="e1"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />

        <SelectOptionFonts
          element="e1"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Arial, sans-serif"
        />
      </CollapsableInputCategory>
    </div>
  )
}
