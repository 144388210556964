import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from './CollapsableInputCategory'
import SelectOption from './SelectOption'
import NumberFieldOption from './NumberFieldOption'
import SliderOption from './SliderOption'
import ColorOption from './ColorOption'
import TextOption from './TextOption'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

export default function AnimationInputExample(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="Text settings">
        <ColorOption
          element="e1"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />
        <TextOption
          element="e1"
          optionName="text"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text"
          defaultValue="Some text here"
        />
        <SliderOption
          element="e1"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="General settings">
        <SelectOption
          element="e1"
          optionName="animation"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation"
          options={[
            ['zoom', 'Zoom'],
            ['zoomClick', 'Zoom click'],
            ['fade', 'Fade'],
            ['swipeRight', 'Swipe right'],
            ['swipeLeft', 'Swipe left'],
            ['swipeUp', 'Swipe up'],
            ['swipeDown', 'Swipe down'],
            ['instant', 'Instant'],
          ]}
          defaultValue="zoom"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationInterval"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDelay"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
      </CollapsableInputCategory>
    </div>
  )
}
