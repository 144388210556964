import React from 'react'
import AnimationInput from './TwitchPrimeModernAnimationInput'

import ConfigurationToolbar from './ConfigurationToolbar'
import StandardConfiguration from '../StandardConfiguration'
import { getCookie, getAccessToken } from '../../../common/cookie'

const drawerWidth = 240

const animationPath = '/animation/twitchPrimeModern.php'

let baseUrl = window.location.hostname

if (baseUrl === 'localhost') {
  baseUrl = 'http://' + baseUrl + ':8000' + animationPath
} else {
  baseUrl = 'https://' + baseUrl + animationPath
}

export default function ControlledExpansionPanels() {
  const [choices, setChoices] = React.useState([])

  const [outputUrl, setOutputUrl] = React.useState('')
  const [previewUrl, setPreviewUrl] = React.useState('')

  // TODO generate outputurl from input
  let outputOptions = []

  function handleOptionChange(option, value) {
    // TODO handle typing, so preview requests are limited
    //TODO make this only send once per change

    let newChoices = choices
    newChoices[option] = value
    setChoices(newChoices)

    outputOptions[option] = value

    setOutputUrl(baseUrl + buildUrlEnding(choices))

    let previewChoices = clone(choices)
    previewChoices['loop'] = 'yes'
    setPreviewUrl(baseUrl + buildUrlEnding(previewChoices))
  }

  //TODO add token
  function buildUrlEnding(outputOptions) {
    let finalUrl = ''
    let keysAdded = 0
    let accessToken = getAccessToken()

    if (accessToken !== '') {
      outputOptions['at'] = accessToken
    }

    for (var key in outputOptions) {
      let prefix = ''
      if (keysAdded++ === 0) {
        prefix += '?'
      } else {
        prefix += '&'
      }

      let value = outputOptions[key]
      finalUrl += prefix + key + '=' + value
    }

    return finalUrl
  }

  function clone(obj) {
    if (obj == null || typeof obj != 'object') return obj

    var temp = new obj.constructor()
    for (var key in obj) temp[key] = clone(obj[key])

    return temp
  }

  const input = <AnimationInput alertParentOptionChange={handleOptionChange} />

  const toolbar = (
    <ConfigurationToolbar
      dashboardCategory="Twitch"
      animationName="Twitch prime variant"
    />
  )

  return (
    <StandardConfiguration
      cost={2}
      toolbar={toolbar}
      input={input}
      outputUrl={outputUrl}
      previewUrl={previewUrl}
    />
  )
}
