import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from '../CollapsableInputCategory'
import SelectOption from '../SelectOption'
import SelectOptionFonts from '../SelectOptionFonts'
import NumberFieldOption from '../NumberFieldOption'
import SliderOption from '../SliderOption'
import ColorOption from '../ColorOption'
import TextOption from '../TextOption'
import CheckboxOption from '../CheckboxOption'
import {
  standardAnimationOptions,
  standardFontOptions,
} from '../AnimationOptions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

export default function AnimationInputExample(props) {
  const classes = useStyles()

  const [includeTwitter, setIncludeTwitter] = React.useState(1)
  const [includeYoutube, setIncludeYoutube] = React.useState(1)
  const [includeInstagram, setIncludeInstagram] = React.useState(1)
  const [includeBackground, setIncludeBackground] = React.useState(1)

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="General settings">
        <NumberFieldOption
          element="e1"
          optionName="animationInterval"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDelay"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDuration"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation duration per social media"
          helpText="(Seconds)"
          defaultValue={8}
        />

        <SliderOption
          element="e2"
          optionName="width"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Icon size"
          defaultValue={50}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.25}
        />

        <SliderOption
          element="f1"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={28}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <ColorOption
          element="f1"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />
        <SelectOption
          element="e1"
          optionName="animation"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation (work in progress)"
          options={standardAnimationOptions()}
          defaultValue="fade"
        />
        <SelectOptionFonts
          element="f1"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Open Sans Condensed, sans-serif"
        />

        <CheckboxOption
          element="b1"
          optionName="includeBgShadow"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Have background shadow"
          defaultValue={1}
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Background settings">
        {false && (
          <CheckboxOption
            element="b1"
            optionName="include"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Include background"
            setFunction={setIncludeBackground}
            defaultValue={1}
            helpText={
              includeBackground
                ? 'Disabling resets all background settings'
                : 'Enabling resets current animation'
            }
          />
        )}
        {includeBackground && (
          <ColorOption
            element="b1"
            optionName="backgroundColor"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Background color"
            defaultValue="#ffffff"
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="width"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Width"
            defaultValue={50}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {true && (
          <SliderOption
            element="b1"
            optionName="borderR"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Border rounding"
            defaultValue={50}
            stepSize={1}
            min={0}
            max={50}
            minText="0%"
            maxText="50%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="opacity"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Background opacity"
            defaultValue={100}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Twitter">
        <CheckboxOption
          element="e2"
          optionName="include"
          parentHandleUpdate={props.alertParentOptionChange}
          setFunction={setIncludeTwitter}
          title="Include Twitter"
          defaultValue={1}
        />
        {includeTwitter && (
          <TextOption
            element="e2"
            optionName="text"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Text"
            defaultValue="streamzombie_"
            helpText=""
          />
        )}
      </CollapsableInputCategory>
      <CollapsableInputCategory title="YouTube">
        <CheckboxOption
          element="e3"
          optionName="include"
          parentHandleUpdate={props.alertParentOptionChange}
          setFunction={setIncludeYoutube}
          title="Include YouTube"
          defaultValue={1}
        />
        {includeYoutube && (
          <TextOption
            element="e3"
            optionName="text"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Text"
            defaultValue="streamzombie"
            helpText=""
          />
        )}
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Instagram">
        <CheckboxOption
          element="e4"
          optionName="include"
          parentHandleUpdate={props.alertParentOptionChange}
          setFunction={setIncludeInstagram}
          title="Include Instagram"
          defaultValue={1}
        />
        {includeInstagram && (
          <TextOption
            element="e4"
            optionName="text"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Text"
            defaultValue="streamzombie"
            helpText=""
          />
        )}
      </CollapsableInputCategory>
    </div>
  )
}
