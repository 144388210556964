import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/common/Copyright'
import Card from '@material-ui/core/Card'
import PrettoSlider from '../components/dashboard/PrettoSlider'

import queryString from 'query-string'
const urlParameters = queryString.parse(window.location.search)

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

function getValueFromCookie(value) {
  let jwt2Content = getCookie('JWT2')

  if (jwt2Content !== '') {
    return parseJwt(jwt2Content)[value]
  }
  return ''
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  root: {},
  paper: {
    marginTop: theme.spacing(6),
  },
  accountCard: {
    marginTop: theme.spacing(24),
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backButton: {
    position: 'fixed',
    left: 24,
    top: 20,
    textAlign: 'left',
    color: 'black',
  },
  sendButton: {
    marginTop: theme.spacing(4),
    background: '#66b574',
    color: 'black',
    width: '140px',
    height: '48px',
  },
  sentText: {
    marginTop: theme.spacing(0),
  },
}))

export default function SignUp() {
  const classes = useStyles()

  const [updateUrl, setUpdateUrl] = React.useState('')
  const [cancelUrl, setCancelUrl] = React.useState('')
  const [nextBillDate, setNextBillDate] = React.useState('')
  const [eventTime, setEventTime] = React.useState('')

  let url_string = window.location.href //window.location.href
  let url = new URL(url_string)
  let sentMessage = url.searchParams.get('sentMessage')

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Link href="/dashboard">
          <Typography variant="h6" className={classes.backButton}>
            Go back
          </Typography>
        </Link>
        <Card mt={4} className={classes.accountCard}>
          <div className={classes.paper}>
            <Typography variant="h4">Contact</Typography>

            <Box mt={4} mb={5} mr={4} ml={4}>
              {!sentMessage && (
                <form action="/backend/includes/contact.php" method="post">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Your email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="message"
                    label="Message"
                    name="message"
                    autoComplete="message"
                    autoFocus
                    multiline
                    rows="4"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    name="contact-submit"
                    className={classes.sendButton}
                  >
                    <Typography align="center">Send</Typography>
                  </Button>
                </form>
              )}

              {sentMessage && sentMessage === 'true' && (
                <Typography
                  component="h1"
                  variant="h6"
                  className={classes.sentText}
                >
                  Message sent, we will get back to you as soon as possible!
                </Typography>
              )}
            </Box>
          </div>
        </Card>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}
