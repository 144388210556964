import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(17.5),
    flexShrink: 0,
    textAlign: 'left',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  shadowOverride: {
    boxShadow: `0px 0px 0px 0px rgba(0,0,0,0.72)`,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '0px',
  },
  panelDetails: {
    flexDirection: 'row',
    justifyContent: 'center',
    justifyContent: 'space-between',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
  },
}))

export default function CollapsableInputCategory(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState('true')

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'true'}
        onChange={handleChange('true')}
        square={true}
        classes={{
          root: classes.shadowOverride, // class name, e.g. `classes-nesting-root-x`
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          expanded="true"
        >
          <Typography variant="h5" className={classes.heading}>
            {props.title}
          </Typography>
          <Typography className={classes.secondaryHeading}>
            {props.explanatoryText}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          <Grid container>{props.children}</Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
