import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

const PrettoSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
    width: '80%',
    marginLeft: '10%',
    marginRight: '10%',
  },
  thumb: {
    height: 0,
    width: 0,
    backgroundColor: '#fff',
    border: '0px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 5,
  },
  rail: {
    height: 10,
    borderRadius: 5,
  },
})(Slider)

export default PrettoSlider
