import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import SideBar from '../components/dashboard/SideBar'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

import DisplayCard from '../components/DisplayCard'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    scrollBehavior: 'smooth',
  },
  appBar: {
    backgroundColor: 'red',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
    color: 'black',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  accountButton: {
    textAlign: 'right',
  },
  categoryTitle: {
    marginTop: theme.spacing(2),
  },
  navToolbar: {
    width: '100%',
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  contentGrid: {
    justifyContent: 'center',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar2: {
    maxHeight: '20px',
  },
  toolbarItem: {
    color: 'black',
    marginLeft: theme.spacing(3),
  },
  toolbarItem2: {
    color: 'black',
    marginLeft: theme.spacing(6),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: theme.mixins.toolbar,
}))

export default function ClippedDrawer() {
  updateToken()
  const classes = useStyles()

  function getOriginDomain() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:8000'
    }
    return window.location.origin
  }

  function updateToken() {
    const Http = new XMLHttpRequest()
    const url = getOriginDomain() + '/backend/updateToken.php'

    Http.open('POST', url)
    Http.send()
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar2}>
          <Typography variant="h6">StreamZombie</Typography>
          <div className={classes.navToolbar}>
            <Link href="/pricing" className={classes.toolbarItem}>
              <Typography variant="h6" className={classes.backButton}>
                Pricing
              </Typography>
            </Link>
            <Link href="/account" className={classes.toolbarItem}>
              <Typography variant="h6" className={classes.backButton}>
                Account
              </Typography>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <SideBar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container spacing={4} className={classes.contentGrid}>
          <Grid item key={1} xs={12} xl={12} id="Text">
            <Typography variant="h4" className={classes.categoryTitle}>
              Text
            </Typography>
          </Grid>

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/text.php?e1_text=Some text here&e1_animationDuration=10&e1_fontSize=9&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
            }
            header="Text"
            body="Simple text, stating something that people should know about"
            link="/text"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/doubleText.php?e2_text=Some text here&e1_animationDuration=10&e2_fontSize=9&e3_text=Some other text&e3_fontSize=6.3&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&e3_textColor=ffffff&loop=yes'
            }
            header="Double text"
            body="Two texts, in case you want different sizes or colors"
            link="/doubleText"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/tripleText.php?e2_text=Some text here&e1_animationDuration=10&e2_fontSize=8.1&e3_text=Some other text&e3_fontSize=5.175&e4_text=Something else&e4_fontSize=9&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&e4_textColor=ffffff&e3_textColor=f000f0&loop=yes'
            }
            header="Triple text"
            body="Three texts, in case you want to make something super fancy"
            link="/tripleText"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/separatedDoubleText.php?e1_text=Some animated text&e1_fontSize=9&e1_animation=swipeDown&e1_animationDuration=10&e1_delay=0&e2_text=Seperate animated text&e2_fontSize=6.75&e2_animation=zoom&e2_animationDuration=6&e2_delay=2&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
            }
            header="Separated double text"
            body="The different texts can have different animations"
            link="/separatedDoubleText"
          />

          <Grid
            item
            key={2}
            xs={12}
            xl={12}
            className={classes.animationCategory}
            id="Time"
          >
            <Typography variant="h4" className={classes.categoryTitle}>
              Time
            </Typography>
          </Grid>

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/countdownTimerWithText.php?e3_text=Eating pizza in&e3_fontSize=14&e1_d=0&e1_h=2&e1_m=0&e1_s=0&e1_textWhenDone=&e1_fontSize=12&e2_fontSize=6'
            }
            header="Countdown with text"
            body="Countdown to an awesome event people should know about"
            link="/countdownTimerWithText"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/countdownTimer.php?e1_d=0&e1_h=1&e1_m=12&e1_s=0&e1_textWhenDone=&e1_fontSize=14&e2_fontSize=7&e2_text=Some text here&e2_textColor=000000&e1_textColor=000000'
            }
            header="Countdown"
            body="Countdown to an awesome event people should know about"
            link="/countdownTimer"
          />

          <Grid
            item
            key={3}
            xs={12}
            xl={12}
            className={classes.animationCategory}
            id="Youtube"
          >
            <Typography variant="h4" className={classes.categoryTitle}>
              Youtube
            </Typography>
          </Grid>

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/latestVideo.php?e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&e2_videoPageLink=youtube.com/user/ernwillburn/videos&e1_text=My latest video&e1_fontSize=9&e2_width=65&e3_fontSize=4.5&e3_width=65&loop=yes'
            }
            header="Latest Youtube video"
            body="Automatically gets your latest youtube video and advertises it "
            link="/latestVideo"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/playlistVideo.php?e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&e2_playlistID=youtube.com/playlist?list=PLAyCLklRAGVQMfBHBIg5PeJl1qHlHROVU&e1_text=My featured video&e1_fontSize=9&e2_width=65&e3_fontSize=4.5&e3_width=65&loop=yes'
            }
            header="Latest playlist video"
            body="Gets video latest added to a playlist"
            link="/playlistVideo"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/video.php?e1_animation=zoom&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&e2_videoID=www.youtube.com/watch?v=kJQP7kiw5Fk&e1_text=Watch my video&e1_fontSize=9&e2_width=65&e3_fontSize=4.5&e3_width=65&loop=yes'
            }
            header="Youtube video"
            body="A specific video you want people to see"
            link="/video"
          />

          <Grid
            item
            key={3}
            xs={12}
            xl={12}
            className={classes.animationCategory}
            id="Twitch"
          >
            <Typography variant="h4" className={classes.categoryTitle}>
              Twitch
            </Typography>
          </Grid>

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/twitchPrime.php?e1_text=Subscribe for free!&e1_fontSize=9&e1_animation=zoom&e1_animationDuration=10&e1_delay=0&e2_width=40&e2_animation=zoom&e2_animationDuration=8&e2_delay=1&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
            }
            header="Twitch prime"
            body="People should know or be reminded that they can subscribe for free"
            link="/twitchPrime"
          />

          <DisplayCard
            data={
              getOriginDomain() +
              '/animation/followOld.php?e1_text=Follow the stream!&e1_fontSize=9&e1_animation=swipeDown&e1_animationDuration=10&e1_delay=0&e2_width=30&e2_animation=zoom&e2_animationDuration=8&e2_delay=1&e1_animationInterval=30&e1_animationDelay=10&loop=yes'
            }
            header="Follow"
            body="People should follow to keep up to date"
            link="/followOld"
          />
        </Grid>
      </main>
    </div>
  )
}
