import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import PrettoSlider from './PrettoSlider'
import Typography from '@material-ui/core/Typography'
import UpgradeButton from './UpgradeButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    backgroundColor: 'white',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  upgradeDivider: {
    marginBottom: theme.spacing(3),
  },
  sideBarDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  sideBarTitle: {
    marginTop: theme.spacing(2),
  },
  sideBarZombieInvasionInfo: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  sideBarZombieInvasionInfo2: {
    width: '80%',
    display: 'inline-block',
    alignItems: 'center',
  },
  avoidZombieSection: {
    marginTop: theme.spacing(4),
  },
}))

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

function getStoredJWTCookieData(key) {
  let jwt2Content = getCookie('JWT2')

  if (jwt2Content !== '') {
    return parseJwt(jwt2Content)[key]
  }
  return '?'
}

export default function UpgradePrompt() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Divider className={classes.upgradeDivider} />
      <Typography variant="h6">Zombie invasion status</Typography>
      <PrettoSlider
        value={
          (100 * getStoredJWTCookieData('userModuleUsesMonth')) /
          getStoredJWTCookieData('userModuleUsesLimitMonth')
        }
      />
      <Typography>
        Monthly usage: {getStoredJWTCookieData('userModuleUsesMonth')}/
        {getStoredJWTCookieData('userModuleUsesLimitMonth')}
      </Typography>

      <div className={classes.avoidZombieSection}>
        <Typography variant="h6">Avoid zombies</Typography>
        <UpgradeButton />
      </div>

      <div className={classes.sideBarZombieInvasionInfo}>
        <Typography className={classes.sideBarZombieInvasionInfo2}>
          Streamzombie is always be free to use. If you exceed your monthly
          quota, a zombie might come and advertise this website after one of
          your popups.
        </Typography>
      </div>
    </div>
  )
}

//175-196
