import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from '../CollapsableInputCategory'
import SelectOption from '../SelectOption'
import NumberFieldOption from '../NumberFieldOption'
import SliderOption from '../SliderOption'
import ColorOption from '../ColorOption'
import TextOption from '../TextOption'
import { standardAnimationOptions } from '../AnimationOptions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

function getOriginDomain() {
  if (window.location.origin.includes('localhost')) {
    return 'http://localhost:3000'
  }
  return window.location.origin
}

export default function AnimationInputExample(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="Source settings">
        <TextOption
          size="double"
          element="e1"
          optionName="source"
          parentHandleUpdate={props.alertParentOptionChangeURL}
          title="URL to source"
          defaultValue={getOriginDomain() + '/example-of-external-url'}
        />
        {false && (
          <SliderOption
            element="e1"
            optionName="width"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Width"
            defaultValue={100}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {false && (
          <SliderOption
            element="e1"
            optionName="height"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Height"
            defaultValue={100}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
      </CollapsableInputCategory>
      <CollapsableInputCategory title="General settings">
        <SelectOption
          element="e1"
          optionName="animation"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation"
          options={standardAnimationOptions()}
          defaultValue="zoom"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDuration"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation duration, seconds"
          defaultValue="10"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationInterval"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDelay"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
      </CollapsableInputCategory>
    </div>
  )
}
