import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/common/Copyright'
import Card from '@material-ui/core/Card'
import PrettoSlider from '../components/dashboard/PrettoSlider'

import queryString from 'query-string'
const urlParameters = queryString.parse(window.location.search)

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

function getValueFromCookie(value) {
  let jwt2Content = getCookie('JWT2')

  if (jwt2Content !== '') {
    return parseJwt(jwt2Content)[value]
  }
  return ''
}

function delete_cookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

function signOut() {
  delete_cookie('JWT')
  delete_cookie('JWT2')
  window.location.replace('/')
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  root: {
    height: '100vw',
  },
  paper: {
    marginTop: theme.spacing(6),
  },
  accountCard: {
    marginTop: theme.spacing(8),
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backButton: {
    position: 'fixed',
    left: 24,
    top: 20,
    textAlign: 'left',
    color: 'black',
  },
  signOutButton: {
    marginBottom: theme.spacing(2),
  },
}))

export default function SignUp() {
  const classes = useStyles()

  const [updateUrl, setUpdateUrl] = React.useState('')
  const [cancelUrl, setCancelUrl] = React.useState('')
  const [nextBillDate, setNextBillDate] = React.useState('')
  const [eventTime, setEventTime] = React.useState('')

  function getOriginDomain() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:8000'
    }
    return window.location.origin
  }

  function getPaymentInfo() {
    const Http = new XMLHttpRequest()
    const url = getOriginDomain() + '/backend/getPaymentInfo.php'

    Http.open('POST', url)
    Http.send()

    let responseContent = []

    Http.onreadystatechange = (e) => {
      if (Http.readyState == XMLHttpRequest.DONE) {
        responseContent = JSON.parse(Http.responseText)
        setUpdateUrl(responseContent.updateUrl)
        setCancelUrl(responseContent.cancelUrl)
        setNextBillDate(responseContent.nextBillDate)
        setEventTime(responseContent.eventTime)
      }
    }
  }

  getPaymentInfo()

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Link href="/dashboard">
          <Typography variant="h6" className={classes.backButton}>
            Go back
          </Typography>
        </Link>
        <Card mt={4} className={classes.accountCard}>
          <div className={classes.paper}>
            <Typography variant="h4">
              Hello, {getValueFromCookie('userUsername')}
            </Typography>

            <Box mt={4} mb={5}>
              <Typography variant="h5">
                Plan: {getValueFromCookie('userLevel') === 0 ? 'free' : ''}
                {getValueFromCookie('userLevel') === 1 ? 'basic' : ''}
                {getValueFromCookie('userLevel') === 2 ? 'pro' : ''}
              </Typography>

              {getValueFromCookie('userLevel') > 0 && (
                <div>
                  <Typography>Next billing date: {nextBillDate}</Typography>
                  <Typography>
                    <Link href={cancelUrl}>Cancel subscription</Link>
                  </Typography>
                  <Typography>
                    <Link href={updateUrl}>Update payment info</Link>
                  </Typography>
                </div>
              )}

              <Box mt={4}>
                <Typography variant="h6">
                  Monthly quota{' '}
                  (<Link
                    href="/watermark-info"
                    target="_blank"
                    style={{ color: 'black', textDecoration: "underline" }}
                  >
                    ?
                  </Link>)
                </Typography>
              </Box>
              <PrettoSlider
                value={
                  (100 * getValueFromCookie('userModuleUsesMonth')) /
                  getValueFromCookie('userModuleUsesLimitMonth')
                }
              />
              <Typography>
                Monthly usage: {getValueFromCookie('userModuleUsesMonth')}/
                {getValueFromCookie('userModuleUsesLimitMonth')}
              </Typography>
            </Box>
            <Button>
              <Typography className={classes.signOutButton}>
                <Link onClick={signOut}>Sign out</Link>
              </Typography>
            </Button>
          </div>
        </Card>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </div>
  )
}
