import React from 'react'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'

import standardTheme from '../common/standardTheme'

const styles = (theme) => ({
  //const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTextContent: {
    flexGrow: 1,
  },
  cardMedia: {
    //paddingTop: '56.25%', // 16:9
    //backgroundColor: "#aabfaa",
    backgroundColor: '#609732',
    backgroundColor: '#8abd5f',
    backgroundColor: '#88cc88',
    backgroundColor: '#cacaca',
    backgroundColor: '#dddddd',
    height: '15vw',
  },
})

DisplayCard.propTypes = {
  data: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

//const classes = styles();
function DisplayCard(props) {
  const { classes } = props

  function _in() {
    console.log('inside card')
  }

  function _out() {
    console.log('outside card')
  }

  return (
    <ThemeProvider theme={standardTheme}>
      <Grid item key={1} xs={12} sm={12} md={6} lg={4} xl={3}>
        <Link href={props.link}>
          <Card className={classes.card} onMouseEnter={_in} onMouseLeave={_out}>
            <object
              className={classes.cardMedia}
              type="text/html"
              data={props.data}
            ></object>
            <CardContent className={classes.cardTextContent}>
              <Typography variant="h5" component="h2">
                {props.header}
              </Typography>
              <Typography variant="body1">{props.body}</Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </ThemeProvider>
  )
}

export default withStyles(styles)(DisplayCard)
