import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AnimationInput from './AnimationInputExample'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

import SideBar from '../dashboard/SideBar'
import AnimationOutput from './AnimationOutput'

const drawerWidth = 240

const animationPath = '/animation/template.php'

let baseUrl = window.location.hostname

if (baseUrl === 'localhost') {
  baseUrl = 'http://' + baseUrl + ':8000' + animationPath
} else {
  baseUrl = 'https://' + baseUrl + animationPath
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

function getAccessToken() {
  let jwt2Content = getCookie('JWT2')

  if (jwt2Content !== '') {
    return parseJwt(jwt2Content)['accessToken']
  }
  return ''
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  inputHalf: {},
  outputHalf: {
    paddingLeft: '1px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(12),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
    color: 'black',
  },
}))

export default function ControlledExpansionPanels() {
  const classes = useStyles()

  const [choices, setChoices] = React.useState([])

  const [outputUrl, setOutputUrl] = React.useState('')
  const [previewUrl, setPreviewUrl] = React.useState('')

  // TODO generate outputurl from input
  let outputOptions = []

  function handleOptionChange(option, value) {
    // TODO handle typing, so preview requests are limited
    //TODO make this only send once per change

    let newChoices = choices
    newChoices[option] = value
    setChoices(newChoices)

    outputOptions[option] = value

    setOutputUrl(baseUrl + buildUrlEnding(choices))

    let previewChoices = clone(choices)
    previewChoices['loop'] = 'yes'
    setPreviewUrl(baseUrl + buildUrlEnding(previewChoices))
  }

  //TODO add token
  function buildUrlEnding(outputOptions) {
    let finalUrl = ''
    let keysAdded = 0
    let accessToken = getAccessToken()

    if (accessToken !== '') {
      outputOptions['at'] = accessToken
    }

    for (var key in outputOptions) {
      let prefix = ''
      if (keysAdded++ === 0) {
        prefix += '?'
      } else {
        prefix += '&'
      }

      let value = outputOptions[key]
      finalUrl += prefix + key + '=' + value
    }

    return finalUrl
  }

  function clone(obj) {
    if (obj == null || typeof obj != 'object') return obj

    var temp = new obj.constructor()
    for (var key in obj) temp[key] = clone(obj[key])

    return temp
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Link href="/dashboard">
            <Typography variant="h6" noWrap>
              StreamZombie
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <SideBar />
      <main className={classes.content}>
        <Grid container alignItems="flex-start">
          <Grid
            item
            key={1}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.inputhalf}
          >
            <AnimationInput alertParentOptionChange={handleOptionChange} />
          </Grid>

          <Grid
            item
            key={2}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={classes.outputHalf}
          >
            <AnimationOutput outputUrl={outputUrl} previewUrl={previewUrl} />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}
