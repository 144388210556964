import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AnimationInput from './TextAnimationInput'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingTop: '-4px',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff',
    textAlign: 'right',
    justifyContent: 'right',
    alignItems: 'left',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow:
      '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12)',
    color: 'black',
  },
  toolbar1: {
    //background: "red",
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'left',
    paddingLeft: theme.spacing(3),
  },
  toolbar2: {
    display: 'flex',
    justifyContent: 'center',
    //background: "green",
  },
  toolbar3: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end',
    //background: "blue",
    paddingRight: theme.spacing(3),
  },
  toolbarItem: {
    color: 'black',
    marginLeft: theme.spacing(3),
  },
}))

export default function ConfigurationToolbar(props) {
  const classes = useStyles()
  return (
    <>
      <div
        style={{
          background: '#66b574',
          height: '4px',
          width: '100%',
          position: 'fixed',
          zIndex: '1202',
          left: '0',
        }}
      />
      <AppBar position="fixed" className={classes.appBar}>
        <Grid container>
          <Grid
            item
            key={1}
            xs={4}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className={classes.toolbar1}
          >
            <Typography variant="h6">
              <Link color="inherit" href="/dashboard">
                Go back
              </Link>
            </Typography>
          </Grid>
          <Grid
            item
            key={2}
            xs={4}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className={classes.toolbar2}
          >
            <Toolbar>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Link
                  color="inherit"
                  href={'/dashboard#' + props.dashboardCategory}
                >
                  {props.dashboardCategory}
                </Link>
                <Typography color="textPrimary">
                  {props.animationName}
                </Typography>
              </Breadcrumbs>
            </Toolbar>
          </Grid>

          <Grid
            item
            key={3}
            xs={4}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className={classes.toolbar3}
          >
            <Typography variant="body1">
              <Link
                color="inherit"
                href="/contact"
                className={classes.toolbarItem}
                target="_blank"
              >
                Help
              </Link>
              <Link
                color="inherit"
                href="/account"
                className={classes.toolbarItem}
                target="_blank"
              >
                Account
              </Link>
            </Typography>
            {/* something could be in the right toolbar*/}
          </Grid>
        </Grid>
      </AppBar>
    </>
  )
}
