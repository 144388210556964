import React from 'react'
//import logo from './logo.svg'
import './App.css'
//import Button from '@material-ui/core/Button'
//import { makeStyles } from '@material-ui/core/styles'
import { Router } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import Routes from './Routes'

//import '@ibm/plex/css/ibm-plex.css';
//import 'font-roboto/dist/styles/roboto.css';
//import 'typeface-ibm-plex-mono/index.css';
//require("typeface-ibm-plex-serif")
//require("typeface-open-sans")

//require("typeface-ibm-plex-mono")
require('typeface-ibm-plex-sans')

// Browser history
const browserHistory = createBrowserHistory()

// TODO is this needed? (probably not?)
/*const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));*/

function App() {
  // TODO is this needed? (probably not?)
  //const classes = useStyles();
  return (
    <div className="App">
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </div>
  )
}

export default App
