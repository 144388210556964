import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },

  root2: {
    width: '100%',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 1,
    textAlign: 'left',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    maxHeight: '100px',
  },
  shadowOverride: {
    boxShadow: `0px 0px 0px 0px rgba(0,0,0,0.1)`,
  },
  panelDetails: {
    flexDirection: 'row',
    flexFlow: 'row wrap',
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  sidebarCategory: {
    color: 'black',
  },
  summary: {
    minHeight: '0px',
  },
}))

export default function CollapsableInputCategory(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState('true')

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'true'}
        onChange={handleChange('true')}
        square={true}
        classes={{
          root: classes.shadowOverride, // class name, e.g. `classes-nesting-root-x`
        }}
        className={classes.root2}
      >
        <ExpansionPanelSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          expanded="true"
        >
          <Link className={classes.sidebarCategory} href={props.href}>
            <Typography className={classes.heading}>{props.title}</Typography>
          </Link>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          {props.children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
