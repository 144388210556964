import React from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import StarIcon from '@material-ui/icons/StarBorder'
import Typography from '@material-ui/core/Typography'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Copyright from '../components/common/Copyright'
import Link from '@material-ui/core/Link'
import Switch from '@material-ui/core/Switch'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

import PeriodicitySwitch from '../components/pricing/PeriodicitySwitch'

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'Helvetica',
      'Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: '500',
    },
    h2: {
      fontWeight: '700',
    },
    h3: {
      fontWeight: '500',
    },
    h4: {
      fontWeight: '500',
    },
    h5: {
      fontWeight: '400',
    },
    h6: {
      fontWeight: '400',
    },
    body1: {
      fontWeight: '400',
    },
  },
})

const themePricingCard = createMuiTheme({
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'Helvetica',
      'Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h3: {
      fontWeight: '500',
    },
    h5: {
      fontWeight: '700',
    },
  },
})

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      margin: 0,
      minheight: '100vh',
      background: 'red',
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 0),
  },
  cardButton2: {
    textAlign: 'center',
    color: 'white',
    background: '#66b574',
  },
  cardButton3: {
    textAlign: 'center',
    color: 'white',
    background: '#66b574',
  },
  backButton: {
    position: 'fixed',
    left: 24,
    top: 20,
    textAlign: 'left',
    color: 'black',
  },
  periodicityText: {
    padding: theme.spacing(0.09, 0, 0),
    color: 'rgba(0, 0, 0, 0.54)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cardHeader1: {
    //backgroundColor: theme.palette.grey[200],
    //backgroundColor: "#0AA756",

    //borderBottom: "2px solid rgba(0,0,0,.2)"
    background: '#e2e3e3',
  },
  cardHeader2: {
    //backgroundImage: "linear-gradient(-179deg,#4FDC6F 0,#0AA756 100%)",
    //backgroundImage: "linear-gradient(-179deg, "+green[200]+" 0,"+green[300]+" 100%)",
    background: '#e2e3e3',
    fontWeight: 700,
  },
  cardHeader3: {
    //backgroundImage: "linear-gradient(-179deg,#ff00ec 0,#ab1582 97%)",
    //backgroundImage: "linear-gradient(-179deg, "+purple[200]+" 0,"+purple[300]+" 100%)",
    background: '#e2e3e3',
  },
  salesTaxText: {
    paddingTop: theme.spacing(5),
  },
  customContactText: {
    paddingTop: theme.spacing(3),
  },

  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  priceOptionPlanCard: {
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  pricingCard: {
    marginTop: theme.spacing(9),
  },
  perSwitch: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 0, 0),
  },
  checked: {},
  track: {},
  promotionalText: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    //width: "50%",
    //background: "#8fc48e",
  },
  benefitItem: {
    marginTop: theme.spacing(2),
  },
}))

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function parseJwt(token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

function getUserID() {
  let jwt2Content = getCookie('JWT2')

  if (jwt2Content !== '') {
    return parseJwt(jwt2Content)['userId']
  }
  return ''
}

function getValueFromCookie(value) {
  let jwt2Content = getCookie('JWT2')

  if (jwt2Content !== '') {
    return parseJwt(jwt2Content)[value]
  }
  return ''
}

export default function Pricing() {
  const classes = useStyles()

  const [periodicity, setPeriodicity] = React.useState('annual')
  // TODO get actual user level
  let userLevel = getValueFromCookie('userLevel')

  const openCheckout = (level) => {
    let productId = 0 //default to mothly level 1

    if (level === 1) {
      if (periodicity === 'monthly') {
        productId = 570017
      } else if (periodicity === 'annual') {
        productId = 570018
      }
    } else if (level === 2) {
      if (periodicity === 'monthly') {
        productId = 570019
      } else if (periodicity === 'annual') {
        productId = 570020
      }
    }

    if (productId !== 0) {
      //TODO check so user is logged in

      let userID = getUserID()

      if (userID !== '') {
        window.Paddle.Setup({ vendor: 101513 })
        window.Paddle.Checkout.open({
          product: productId,
          passthrough: '{"id": ' + userID + ', "other": "none"}',
          success:
            'https://streamzombie.com/dashboard?payment=success&paymentLevel=' +
            level,
        })
      } else {
        // TODO user is not logged in, create notification or something
        console.log('user is not logged in')

        //example passthrough: {"id": 7, "other": "none"}
        let pt = '{"id": ' + 7 + ', "other": "none"}'
        console.log('passthrough: ' + pt)
      }
    }
  }

  const handleChange = (name) => (event) => {
    if (periodicity === 'monthly') {
      setPeriodicity('annual')
    } else if (periodicity === 'annual') {
      setPeriodicity('monthly')
    }
  }

  const importPaddle = () => {
    var aScript = document.createElement('script')
    aScript.type = 'text/javascript'
    aScript.src = 'https://cdn.paddle.com/paddle/paddle.js'

    document.head.appendChild(aScript)
  }

  importPaddle()

  //TODO actually give discount in proportion to elapsed % of billing period

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Link href="/dashboard">
          <Typography variant="h6" className={classes.backButton}>
            Go back
          </Typography>
        </Link>
        {/* Hero unit */}
        <Container
          maxWidth="sm"
          component="main"
          className={classes.heroContent}
          id="frag"
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Pricing
          </Typography>
          {false && (
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              className={classes.salesTaxText}
            >
              This is a hobby project, your support is appreciated greatly, get
              extra features when supporting.
            </Typography>
          )}

          <Typography
            variant="h5"
            align="center"
            color="textSecondary"
            component="p"
          >
            First 30 days are free with any subscription, cancel any time on
            your <Link href="/account"
              target="_blank" style={{color: "rgba(0, 0, 0, 0.54)", textDecoration: "underline"}}>account page</Link>, no questions asked.
          </Typography>
        </Container>
        {/* End hero unit */}

        <Container maxWidth="md" component="main">
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ fontWeight: 600, marginTop: '38px' }}
          >
            Billing frequency
          </Typography>
          <Box mt={1} className={classes.perSwitch}>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.periodicityText}
            >
              Quarterly
            </Typography>
            <Switch
              checked={periodicity === 'annual'}
              onChange={handleChange()}
              classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
            />
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.periodicityText}
            >
              Annually
            </Typography>
          </Box>
          <ThemeProvider theme={themePricingCard}>
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item xs={12} md={4} className={classes.pricingCard}>
                <Card className={classes.priceOptionPlanCard}>
                  <CardHeader
                    title="Free"
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader1}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        $0
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        {periodicity === 'monthly' && '/quarter'}
                        {periodicity === 'annual' && '/year'}
                      </Typography>
                    </div>
                    <ul>
                      <Typography
                        component="li"
                        variant=""
                        align="center"
                        className={classes.benefitItem}
                      >
                        300 reduced watermark quota/month{' '} (
                        <Link href="/watermark-info" style={{ color: 'black', textDecoration: "underline" }}>
                          ?
                        </Link>)
                      </Typography>
                      <Typography
                        component="li"
                        variant=""
                        align="center"
                        className={classes.benefitItem}
                      >
                        Unlimited use
                      </Typography>
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth color="inherit" disabled>
                      You have this
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className={classes.priceOptionPlanCard}>
                  <CardHeader
                    title="Basic"
                    subheader="Most popular"
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={null}
                    className={classes.cardHeader2}
                    style={{ fontWeight: 700 }}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        {periodicity === 'monthly' && '$6'}
                        {periodicity === 'annual' && '$20'}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        {periodicity === 'monthly' && '/quarter'}
                        {periodicity === 'annual' && '/year'}
                      </Typography>
                    </div>
                    <ul>
                      <Typography
                        component="li"
                        variant=""
                        align="center"
                        className={classes.benefitItem}
                      >
                        1,500 watermark-free <br />
                        quota/month{' '}
                        (<Link href="/watermark-info" style={{ color: 'black', textDecoration: "underline" }}>
                          ?
                        </Link>)
                      </Typography>
                      <Typography
                        component="li"
                        variant=""
                        align="center"
                        className={classes.benefitItem}
                      >
                        Basic support
                      </Typography>
                    </ul>
                  </CardContent>
                  <CardActions>
                    {userLevel >= 1 && (
                      <Button fullWidth color="inherit" disabled>
                        You have this
                      </Button>
                    )}
                    {userLevel === 0 && userLevel !== '' && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        onClick={() => openCheckout(1)}
                        className={classes.cardButton2}
                      >
                        Upgrade
                      </Button>
                    )}
                    {userLevel === '' && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        href="/signin"
                        className={classes.cardButton2}
                      >
                        Sign in
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card className={classes.priceOptionPlanCard}>
                  <CardHeader
                    title="Pro"
                    subheader="Best value"
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={false ? <StarIcon /> : null}
                    className={classes.cardHeader3}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        {periodicity === 'monthly' && '$36'}
                        {periodicity === 'annual' && '$120'}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        {periodicity === 'monthly' && '/quarter'}
                        {periodicity === 'annual' && '/year'}
                      </Typography>
                    </div>
                    <ul>
                      <Typography
                        component="li"
                        variant=""
                        align="center"
                        className={classes.benefitItem}
                      >
                        10,000 watermark-free <br />
                        quota/month{' '}
                        (<Link href="/watermark-info" style={{ color: 'black', textDecoration: "underline" }}>
                          ?
                        </Link>)
                      </Typography>
                      <Typography
                        component="li"
                        variant=""
                        align="center"
                        className={classes.benefitItem}
                      >
                        Priority support
                      </Typography>
                    </ul>
                  </CardContent>
                  <CardActions>
                    {userLevel >= 2 && (
                      <Button fullWidth color="inherit" disabled>
                        You have this
                      </Button>
                    )}
                    {userLevel === 1 && userLevel !== '' && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        onClick={() => openCheckout(2)}
                        className={classes.cardButton3}
                        disabled
                      >
                        Locked*
                      </Button>
                    )}
                    {userLevel === 0 && userLevel !== '' && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        onClick={() => openCheckout(2)}
                        className={classes.cardButton3}
                      >
                        Upgrade
                      </Button>
                    )}
                    {userLevel === '' && (
                      <Button
                        fullWidth
                        variant="contained"
                        color="inherit"
                        href="/signin"
                        className={classes.cardButton2}
                      >
                        Sign in
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>

        {false && (
          <Button>
            <SwapHorizIcon style={{ fontSize: '46px' }} />
            <Typography variant="h6">Swap to quarterly billing</Typography>
          </Button>
        )}
        {userLevel === 1 && (
          <Box mt={4}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              *Please first cancel your basic subscription on the <Link href="/account"
              target="_blank" style={{color: "rgba(0, 0, 0, 0.54)", textDecoration: "underline"}}>account page</Link> if you
              want to upgrade. Better solution will come in the near future.
            </Typography>
          </Box>
        )}

        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          className={classes.salesTaxText}
        >
          Sales tax/VAT is included in the price.
        </Typography>

        <Link href="/contact" target="_blank">
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            className={classes.customContactText}
          >
            If you want something custom - contact us!
          </Typography>
        </Link>

        {false && (
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            className={classes.promotionalText}
          >
            <b>50% off </b>with coupon code LAUNCH
          </Typography>
        )}

        {userLevel === -1337 && (
          <Box mt={4}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              component="p"
            >
              If you upgrade you will reviece a discount in proportion to how
              much is left of your current billing period.
            </Typography>
          </Box>
        )}
        <Box mt={4}>
          <Copyright />
        </Box>
      </React.Fragment>
    </ThemeProvider>
  )
}
