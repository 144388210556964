import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from '../CollapsableInputCategory'
import SelectOption from '../SelectOption'
import SelectOptionFonts from '../SelectOptionFonts'
import NumberFieldOption from '../NumberFieldOption'
import SliderOption from '../SliderOption'
import ColorOption from '../ColorOption'
import TextOption from '../TextOption'
import {
  standardAnimationOptions,
  standardFontOptions,
} from '../AnimationOptions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

export default function AnimationInputExample(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="General settings">
        <SelectOption
          element="e1"
          optionName="animation"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation"
          options={standardAnimationOptions()}
          defaultValue="zoom"
        />

        <NumberFieldOption
          element="e1"
          optionName="animationDuration"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation duration, seconds"
          defaultValue="10"
        />

        <NumberFieldOption
          element="e1"
          optionName="animationInterval"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDelay"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Text 1 settings">
        <ColorOption
          element="e2"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />
        <TextOption
          element="e2"
          optionName="text"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text"
          defaultValue="Some text here"
        />
        <SliderOption
          element="e2"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <SelectOptionFonts
          element="e2"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Arial, sans-serif"
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Text 2 settings">
        <ColorOption
          element="e3"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />
        <TextOption
          element="e3"
          optionName="text"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text"
          defaultValue="Some other text"
        />
        <SliderOption
          element="e3"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <SelectOptionFonts
          element="e3"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Arial, sans-serif"
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Text 3 settings">
        <ColorOption
          element="e4"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />
        <TextOption
          element="e4"
          optionName="text"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text"
          defaultValue="Something else"
        />
        <SliderOption
          element="e4"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <SelectOptionFonts
          element="e4"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Arial, sans-serif"
        />
      </CollapsableInputCategory>
    </div>
  )
}
