import React from 'react'
import { withStyles } from '@material-ui/core/styles'
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Link from '@material-ui/core/Link'

const styles = (theme) => ({
  //const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '10',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    //boxShadow: "0 2px 4px rgba(0,0,0,.2)",
    //border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '0px',
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: '0 2px 10px rgba(0,0,0,0)',
    //marginRight: theme.spacing(2)
  },
  cardTextContent: {
    flexGrow: 1,
  },
  cardMedia: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffff0f00',
    //height: "15vw"
    boxSizing: 'content-box',
    pointerEvents: 'none',
  },
  cardHeader: {
    //background: "rgba(0, 0, 0, 1)",
    //fontSize: "1.2rem",
    //marginTop: "-100px",
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
    zIndex: '11',
    fontSize: '18px',
    //float: "left",
    //marginLeft: "15px",
  },
  cardHeaderContinue: {
    //background: "rgba(0, 0, 0, 1)",
    //fontSize: "1.2rem",
    //marginTop: "-100px",
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
    zIndex: '11',
    fontSize: '18px',
    float: 'right',
    marginRight: '15px',
  },
  aspectRationFixer: {
    position: 'relative',
    //paddingTop: '56.25%', // 16:9
    paddingBottom: '75.0%', // 4:3
  },
})

DisplayCardTransparent.propTypes = {
  data: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
}

//const classes = styles();
function DisplayCardTransparent(props) {
  const { classes } = props

  // TODO should these be removed?
  function _in() {
    //console.log("inside card");
  }

  function _out() {
    //console.log("outside card");
  }

  return (
    <Grid
      className={classes.root}
      item
      key={1}
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={3}
    >
      <Link href={'/signup'}>
        <Card className={classes.card} onMouseEnter={_in} onMouseLeave={_out}>
          <div className={classes.aspectRationFixer}>
            <object
              className={classes.cardMedia}
              type="text/html"
              data={props.data}
            ></object>
          </div>
        </Card>
      </Link>

      <Typography gutterBottom variant="body1" className={classes.cardHeader}>
        {props.header}
      </Typography>

      {false && (
        <Typography
          gutterBottom
          variant="body1"
          className={classes.cardHeaderContinue}
        >
          {'Continue'}
        </Typography>
      )}
    </Grid>
  )
}

export default withStyles(styles)(DisplayCardTransparent)
