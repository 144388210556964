import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CollapsableInputCategory from '../CollapsableInputCategory'
import SelectOption from '../SelectOption'
import SelectOptionFonts from '../SelectOptionFonts'
import NumberFieldOption from '../NumberFieldOption'
import UploadFile from '../UploadFile'
import SliderOption from '../SliderOption'
import ColorOption from '../ColorOption'
import TextOption from '../TextOption'
import ImageOption from '../ImageOption'
import CheckboxOption from '../CheckboxOption'
import {
  standardAnimationOptions,
  standardBackgroundAnimationOptions,
  standardFontOptions,
  standardAnimationOptionsEnabledBackground,
} from '../AnimationOptions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
}))

function updateOthers(hasBackground) {
  console.log('hasBackground: ' + hasBackground)
}

export default function AnimationInputExample(props) {
  const classes = useStyles()

  const [includeBackground, setIncludeBackground] = React.useState(false)

  return (
    <div className={classes.root}>
      <CollapsableInputCategory title="General settings">
        {!includeBackground && (
          <SelectOption
            element="e1"
            optionName="animation"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Animation"
            options={standardAnimationOptions()}
            defaultValue="zoom"
          />
        )}
        {includeBackground && (
          <SelectOption
            element="e1"
            optionName="animation"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Animation"
            options={standardAnimationOptionsEnabledBackground()}
            defaultValue="instant"
            helpText="Change 'Background animation' instead, unless you really want to stack animations"
          />
        )}

        <NumberFieldOption
          element="e1"
          optionName="animationDuration"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Animation duration, seconds"
          defaultValue="10"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationInterval"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes between popups"
          defaultValue="30"
        />
        <NumberFieldOption
          element="e1"
          optionName="animationDelay"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Minutes before first popup"
          defaultValue="10"
        />
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Image settings ">
        <ImageOption
          element="i1"
          optionName="path"
          title="Image"
          parentHandleUpdate={props.alertParentOptionChange}
          helpText="You can choose from the images you have previously uploaded."
          defaultValue=""
        />

        <UploadFile
          parentHandleUpdate={props.alertParentOptionChange}
          title="Upload new image"
          helpText="Refresh this page after uploading"
        />

        <SliderOption
          element="i1"
          optionName="width"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Image size"
          defaultValue={70}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={1}
        />
      </CollapsableInputCategory>

      <CollapsableInputCategory title="Background settings">
        <CheckboxOption
          element="b1"
          optionName="include"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Include background"
          setFunction={setIncludeBackground}
          defaultValue={0}
          helpText={
            includeBackground
              ? 'Disabling resets all background settings'
              : 'Enabling resets current animation'
          }
        />
        {includeBackground && (
          <SelectOption
            element="b1"
            optionName="animation"
            parentHandleUpdate={props.alertParentOptionChange}
            title=" Background animation"
            options={standardBackgroundAnimationOptions()}
            defaultValue="zoom"
          />
        )}
        {includeBackground && (
          <ColorOption
            element="b1"
            optionName="backgroundColor"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Background color"
            defaultValue="#cae1c4"
            defaultValue="#ffffff"
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="width"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Width"
            defaultValue={90}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="height"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Height"
            defaultValue={80}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="borderR"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Border rounding"
            defaultValue={5}
            stepSize={1}
            min={0}
            max={50}
            minText="0%"
            maxText="50%"
            multiplier={1}
          />
        )}
        {includeBackground && (
          <SliderOption
            element="b1"
            optionName="opacity"
            parentHandleUpdate={props.alertParentOptionChange}
            title="Background opacity"
            defaultValue={100}
            stepSize={1}
            min={0}
            max={100}
            minText="0%"
            maxText="100%"
            multiplier={1}
          />
        )}
      </CollapsableInputCategory>
      <CollapsableInputCategory title="Text settings">
        <ColorOption
          element="e1"
          optionName="textColor"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text color"
          defaultValue="#000000"
        />
        <TextOption
          element="e1"
          optionName="text"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text"
          defaultValue="Some text here"
        />
        <SliderOption
          element="e1"
          optionName="fontSize"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text size"
          defaultValue={40}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={0.225}
        />
        <SelectOptionFonts
          element="e1"
          optionName="font"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Font"
          options={standardFontOptions()}
          defaultValue="Arial, sans-serif"
        />
        <SliderOption
          element="e1"
          optionName="width"
          parentHandleUpdate={props.alertParentOptionChange}
          title="Text width wrap"
          helpText="Width before starting a new line"
          defaultValue={70}
          stepSize={1}
          min={0}
          max={100}
          minText="0%"
          maxText="100%"
          multiplier={1}
        />
      </CollapsableInputCategory>
    </div>
  )
}
