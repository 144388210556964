import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

// Views

import Index from './pages/Index'
import About from './pages/About'
import Dashboard from './pages/Dashboard'
import Dashboard2 from './pages/Dashboard2'
import Dashboard3 from './pages/Dashboard3'
import SignUp from './pages/SignUp'
import SignIn from './pages/SignIn'
import WatermarkInfo from './pages/WatermarkInfo'
import ForgotPassword from './pages/ForgotPassword'
import Contact from './pages/Contact'
import CreateNewPassword from './pages/CreateNewPassword'
import Pricing from './pages/Pricing'
import Account from './pages/Account'
import UploadImage from './pages/UploadImage'
import AnimationInput from './components/animationInput/AnimationInput'

import ExternalExample from './components/examples/ExternalExample.jsx'
import ExternalExampleForDashboard from './components/examples/ExternalExampleForDashboard.jsx'

import AnimationConfiguration from './components/animationInput/AnimationConfiguration'
import TextAnimationConfiguration from './components/animationInput/animations/TextAnimationConfiguration'
import DoubleTextAnimationConfiguration from './components/animationInput/animations/DoubleTextAnimationConfiguration'
import TripleTextAnimationConfiguration from './components/animationInput/animations/TripleTextAnimationConfiguration'
import CountdownTimerAnimationConfiguration from './components/animationInput/animations/CountdownTimerAnimationConfiguration'
import CountdownTimerWithTextAnimationConfiguration from './components/animationInput/animations/CountdownTimerWithTextAnimationConfiguration'
import LatestVideoAnimationConfiguration from './components/animationInput/animations/LatestVideoAnimationConfiguration'
import PlaylistVideoAnimationConfiguration from './components/animationInput/animations/PlaylistVideoAnimationConfiguration'

import VideoAnimationConfiguration from './components/animationInput/animations/VideoAnimationConfiguration'

import TwitchPrimeAnimationConfiguration from './components/animationInput/animations/TwitchPrimeAnimationConfiguration'

import TwitchPrimeModernAnimationConfiguration from './components/animationInput/animations/TwitchPrimeModernAnimationConfiguration'
import PrimeGamingAnimationConfiguration from './components/animationInput/animations/PrimeGamingAnimationConfiguration'

import SeparatedDoubleTextAnimationConfiguration from './components/animationInput/animations/SeparatedDoubleTextAnimationConfiguration'

import FollowOldAnimationConfiguration from './components/animationInput/animations/FollowOldAnimationConfiguration'

import ExternalAnimationConfiguration from './components/animationInput/animations/ExternalAnimationConfiguration'
import SocialsAnimationConfiguration from './components/animationInput/animations/SocialsAnimationConfiguration'

import SocialsRoundAnimationConfiguration from './components/animationInput/animations/SocialsRoundAnimationConfiguration'

import ImageWithTextAnimationConfiguration from './components/animationInput/animations/ImageWithTextAnimationConfiguration'

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route component={About} exact path="/about" />
        <Route component={Account} exact path="/account" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Dashboard} exact path="/dashboard" />
        {false && <Route component={Dashboard2} exact path="/dashboard2" />}
        <Route component={Dashboard3} exact path="/dashboard3" />
        <Route component={AnimationInput} path="/animationinput" />
        <Route component={AnimationConfiguration} path="/ac" />
        <Route component={TextAnimationConfiguration} path="/text" />
        <Route
          component={DoubleTextAnimationConfiguration}
          path="/doubleText"
        />
        <Route
          component={TripleTextAnimationConfiguration}
          path="/tripleText"
        />
        <Route
          component={CountdownTimerAnimationConfiguration}
          path="/countdownTimer"
        />
        <Route
          component={CountdownTimerWithTextAnimationConfiguration}
          path="/countdownTimerWithText"
        />
        <Route
          component={LatestVideoAnimationConfiguration}
          path="/latestVideo"
        />
        <Route
          component={PlaylistVideoAnimationConfiguration}
          path="/playlistVideo"
        />
        <Route component={VideoAnimationConfiguration} path="/video" />
        <Route
          component={SocialsAnimationConfiguration}
          exact
          path="/socials"
        />

        <Route
          component={SocialsRoundAnimationConfiguration}
          exact
          path="/socialsRound"
        />

        <Route
          component={SeparatedDoubleTextAnimationConfiguration}
          path="/separatedDoubleText"
        />

        <Route
          component={TwitchPrimeAnimationConfiguration}
          path="/twitchPrime"
        />

        <Route
          component={TwitchPrimeModernAnimationConfiguration}
          path="/twitchPrimeModern"
        />

        <Route
          component={PrimeGamingAnimationConfiguration}
          path="/primeGaming"
        />

        <Route component={FollowOldAnimationConfiguration} path="/followOld" />

        <Route
          component={ImageWithTextAnimationConfiguration}
          path="/imageWithText"
        />

        <Route component={ExternalAnimationConfiguration} path="/external" />

        <Route component={ExternalExample} path="/example-of-external-url" />

        <Route
          component={ExternalExampleForDashboard}
          path="/example-of-external-url2"
        />

        <Route component={WatermarkInfo} path="/watermark-info" />

        <Route component={SignUp} path="/signup" />
        <Route
          component={SignIn}
          key="/signin/:query?"
          path="/signin/:query?"
        />

        <Route
          component={UploadImage}
          key="/upload_image/:query?"
          path="/upload_image/:query?"
        />

        <Route component={ForgotPassword} path="/forgot-password" />
        <Route component={CreateNewPassword} path="/create-new-password" />
        <Route component={Pricing} path="/pricing" />
        <Route component={Index} path="/" />
        <Redirect to="/" />
      </Switch>
    )
  }
}
