import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'

import Grid from '@material-ui/core/Grid'

import DisplayCard from '../components/DisplayCard'
import UpgradePrompt from '../components/dashboard/UpgradePrompt'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sideBarDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  categoryTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function PersistentDrawerLeft() {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Stream Zombie modules
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider className={classes.sideBarDivider} />
        <UpgradePrompt />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Grid container spacing={4}>
          <Grid item key={1} xs={12} xl={12}>
            <Typography variant="h4" className={classes.categoryTitle}>
              Social media
            </Typography>
          </Grid>

          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=countdownTimerAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26txt-when-done%3D%26d%3D%26h%3D10%26m%3D14%26s%3D42%26txt-col%3D000000"
            header="Time"
            body="Countdown people should know about?"
          />

          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=socialsAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26ig%3Dyour%20instagram%26yt%3Dyour%20youtube%26sc%3Dyour%20snapchat%26tw%3Dyour%20twitter%26fb%3Dyour%20facebook%26txt-col%3D0ff000%26f%3D0.5"
            header="Social media"
            body="Remind people that they can follow you on other platforms"
          />

          <Grid item key={1} xs={12} xl={12}>
            <Typography variant="h4" className={classes.categoryTitle}>
              Time
            </Typography>
          </Grid>

          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=countdownTimerAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26txt-when-done%3D%26d%3D%26h%3D10%26m%3D14%26s%3D42%26txt-col%3D000000"
            header="Time"
            body="Countdown people should know about?"
          />

          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=countdownTimerAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26txt-when-done%3D%26d%3D%26h%3D10%26m%3D14%26s%3D42%26txt-col%3D000000"
            header="Time"
            body="Countdown people should know about?"
          />
          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=countdownTimerAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26txt-when-done%3D%26d%3D%26h%3D10%26m%3D14%26s%3D42%26txt-col%3D000000"
            header="Time"
            body="Countdown people should know about?"
          />
          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=countdownTimerAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26txt-when-done%3D%26d%3D%26h%3D10%26m%3D14%26s%3D42%26txt-col%3D000000"
            header="Time"
            body="Countdown people should know about?"
          />
          <DisplayCard
            data="https://streamzombie.com/o.php?d=&url=countdownTimerAnim.php%3Ftoken%3Db7097ba787cad67eb%20%26txt-when-done%3D%26d%3D%26h%3D10%26m%3D14%26s%3D42%26txt-col%3D000000"
            header="Time"
            body="Countdown people should know about?"
          />
        </Grid>
      </main>
    </div>
  )
}
