import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'

import ProductHowItWorks from '../components/ProductHowItWorks'
import DisplayCard from '../components/DisplayCardIndex'
import DisplayCardTransparent from '../components/DisplayCardIndexTransparent'
import CopyrightFooter from '../components/CopyrightFooter'
import SignupButton from '../components/index/SignupButton'
import CookieConcent from '../components/index/CookieConcent'
import styled from 'styled-components'
import { keyframes } from 'styled-components'

import { getCookie } from '../common/cookie'

let popupsBackground = '#a2d9ff'
popupsBackground = '#006db3'
popupsBackground = '#00bbff'
popupsBackground = '#0099ff'
popupsBackground = '#9FCC9F'
popupsBackground = '#8fc48e'

//66b574

const standardTheme = createMuiTheme({
  typography: {
    color: 'white',
    fontFamily: [
      'IBM Plex Sans',
      'Helvetica',
      'Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: '500',
    },
    h2: {
      fontWeight: '500',
    },
    h3: {
      fontWeight: '500',
    },
    h4: {
      fontWeight: '500',
    },
    h5: {
      fontWeight: '300',
    },
  },
})

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      //backgroundColor: '#f6f6f6',
      //backgroundImage: `/*Created with svgbackgrounds.com*/ url('data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E')`,
    },

    //url('data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-1600 0 3498 888'%3E%3Cpolygon fill='%23805757' points='957 450 539 900 1397 900'/%3E%3Cpolygon fill='%23aa6666' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%238d5860' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%23a85e6a' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%2398596c' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%23a45770' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23a25b7b' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%239d5278' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23a85f8d' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%23934e80' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23aa64a2' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23854c88' points='943 900 1210 900 971 687'/%3E%3C/svg%3E')
  },
  main: {
    background: 'white',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(8),
    backgroundColor: '#ffffff',
    borderBottom: '0px solid rgba(0, 0, 0, 0.12)',
    zIndex: 1,
  },
  heroContent1: {
    borderBottom: '0px solid rgba(0, 0, 0, 0.12)',
    borderTop: '0px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.background.paper,
    //padding: theme.spacing(8, 0, 6),
    paddingTop: theme.spacing(0),
    color: 'black',
  },
  heroContent2: {
    //backgroundImage: `url('data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23efefef' fillOpacity='1'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E');`,
    paddingTop: theme.spacing(1),
    borderTop: '0px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'red',
    backgroundColor: popupsBackground,

    zIndex: 10,
    '@media (max-width:1400px)': {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(0),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
  },
  animationDisplayItem: {
    // TODO fix this ratio
    height: '10vw',
  },
  animationDisplayItemDisplay: {
    background: '#efefef',
    // TODO fix this ratio
    margin: '10%',
    height: '80%',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  categoryTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  cardGrid: {
    width: '85vw',
    //paddingTop: theme.spacing(8),
    //paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(4),
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  signinButton: {
    position: 'fixed',
    top: 18,
    right: 32,
  },
  exampleText: {
    marginTop: 0,
    marginBottom: 64,
  },
  signupButton: {
    marginTop: theme.spacing(3),
    zIndex: 100,
  },
  signupButton2: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    zIndex: 100,
  },
  wave1: {
    marginBottom: '-50px',
    marginTop: '-256px',
    zIndex: '-10',
    '@media (max-width:1400px)': {
      opacity: 0,
    },
  },
  wave2: {
    marginBottom: '-150px',
    marginTop: '-100px',
    zIndex: '-10',
    '@media (max-width:1400px)': {
      opacity: 0,
    },
  },
  shapeBackground: {
    position: 'relative',
    backgroundColor: 'blue',
    width: '100%',
    height: '0px',
    zIndex: 1,
    opacity: 0,
    '@media (min-width:1900px)': {
      opacity: 0,
    },
  },
  xlBubble: {
    backgroundColor: '#f1f1f1',
    backgroundColor: '#E1EAE1',

    borderRadius: '115px',
    width: '230px',
    position: 'absolute',
    height: '230px',
  },
  bigBubble: {
    backgroundColor: '#f1f1f1',
    backgroundColor: '#E1EAE1',
    borderRadius: '70px',
    position: 'absolute',
    width: '140px',
    height: '140px',
  },
  smallBubble: {
    backgroundColor: '#f1f1f1',
    backgroundColor: '#E1EAE1',
    borderRadius: '35px',
    position: 'absolute',
    width: '70px',
    height: '70px',
  },
  benefits: {
    zIndex: 100,
    position: 'relative',
    '@media (max-width:1400px)': {
      marginTop: '54px',
    },
  },
}))

export default function Index() {
  if (getCookie('JWT') !== '') {
    window.location.replace('/dashboard')
  }

  const classes = useStyles()

  function getOriginDomain() {
    if (window.location.origin.includes('localhost')) {
      return 'http://localhost:8000'
    }
    return window.location.origin
  }

  var spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

  const animationStyle = `display: inline-block;
  margin: 10%;
  width: 80%;
  height: 80%;`

  // Create the keyframes
  const circlePop = keyframes`
  0% { clip-path: circle(0% at 50% 50%); }
  5% { clip-path: circle(75%); }
  95% { clip-path: circle(75%); }
  100% { clip-path: circle(0% at 50% 50%); }
`

  const fade = keyframes`
  0% { opacity: 0; }
  7.5% { opacity: 1; }
  92.5% { opacity: 1; }
  100% { opacity: 0; }
`
  const polygonPop = keyframes`
0% { clip-path:  polygon(50% 50%, 50% 25%, 50% 50%, 75% 50%, 50% 50%, 50% 75%, 50% 50%, 25% 50%); }
5% { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
95% { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
100% { clip-path:  polygon(50% 50%, 50% 25%, 50% 50%, 75% 50%, 50% 50%, 50% 75%, 50% 50%, 25% 50%); }
`
  const spinClockwise = keyframes`
0%   { transform: scale(0) rotate(-90deg);  }
5%  { transform: scale(1) rotate(0deg); }
95%  { transform: scale(1) rotate(0deg); }
100% { transform: scale(0) rotate(90deg);  }
`
  const spinCounterClockwise = keyframes`
0%   { transform: scale(0) rotate(90deg);  }
5%  { transform: scale(1) rotate(0deg); }
95%  { transform: scale(1) rotate(0deg); }
100% { transform: scale(0) rotate(-90deg);  }
`
  const swipeDown = keyframes`
0%   { transform: translate(0px, -100vh); -webkit-transform: translate(0px, -100vh);}
10%   { -webkit-transform: scale(1); }
90%   { -webkit-transform: scale(1); }
100%  { transform: translate(0px, -100vh); -webkit-transform: translate(0px, -100vh);}
`
  const swipeLeft = keyframes`
0%   { transform: translate(90vw, 0px); -webkit-transform: translate(100vw, 0px);}
10%   { -webkit-transform: scale(1); }
90%   { -webkit-transform: scale(1); }
100%   { transform: translate(90vw, 0px); -webkit-transform: translate(100vw, 0px);}
`
  const swipeRight = keyframes`
0%   { transform: translate(-90vw, 0px); -webkit-transform: translate(-100vw, 0px);}
10%   { -webkit-transform: scale(1); }
90%   { -webkit-transform: scale(1); }
100%   { transform: translate(-90vw, 0px); -webkit-transform: translate(-100vw, 0px);}
`
  const swipeUp = keyframes`
0%   { transform: translate(0px, 100vh); -webkit-transform: translate(0px, 100vh);}
10%   { -webkit-transform: scale(1); }
90%   { -webkit-transform: scale(1); }
100%  { transform: translate(0px, 100vh); -webkit-transform: translate(0px, 100vh);}
`
  const zoom = keyframes`
0%   {  transform: scale(0); -webkit-transform: scale(0);}
5%   {  transform: scale(1); -webkit-transform: scale(1)}
95%  { transform: scale(1); -webkit-transform: scale(1) }
100% { transform: scale(0); -webkit-transform: scale(0);}
`
  const zoomClick = keyframes`
0%   {  transform: scale(0); -webkit-transform: scale(0);}
5%   {  transform: scale(1); -webkit-transform: scale(1)}
47.5%   {  transform: scale(1); -webkit-transform: scale(1)}
50%   {  transform: scale(0.666); -webkit-transform: scale(0.666)}
52.5%   {  transform: scale(1); -webkit-transform: scale(1)}
95%   { transform: scale(1); -webkit-transform: scale(1)}
100% { transform: scale(0); -webkit-transform: scale(0);}
`
  const zoomJump = keyframes`
0%   {  transform: scale(0); -webkit-transform: scale(0);}
4%   { transform: scale(1.1); -webkit-transform: scale(1.1)}
5%   {  transform: scale(1); -webkit-transform: scale(1)}

95%   { transform: scale(1); -webkit-transform: scale(1)}
96%   { transform: scale(1.1); -webkit-transform: scale(1.1)}
100% { transform: scale(0); -webkit-transform: scale(0);}
`

  // Here we create a component that will rotate everything we pass in over two seconds
  const Animation1 = styled.div`
    animation: ${circlePop} 10s 1s linear infinite;
  `
  const Animation2 = styled.div`
    animation: ${fade} 10s 2s linear infinite;
  `
  const Animation3 = styled.div`
    animation: ${polygonPop} 10s 3s linear infinite;
  `
  const Animation4 = styled.div`
    animation: ${spinClockwise} 10s 4s linear infinite;
  `
  const Animation9 = styled.div`
    animation: ${swipeLeft} 10s 5s linear infinite;
  `
  const Animation10 = styled.div`
    animation: ${zoom} 10s 6s linear infinite;
  `
  const Animation11 = styled.div`
    animation: ${zoomClick} 10s 7s linear infinite;
  `
  const Animation12 = styled.div`
    animation: ${zoomJump} 10s 8s linear infinite;
  `

  const Animation5 = styled.div`
    animation: ${spinCounterClockwise} 10s 0s linear infinite;
  `
  const Animation6 = styled.div`
    animation: ${swipeRight} 10s 0s linear infinite;
  `
  const Animation7 = styled.div`
    animation: ${swipeUp} 10s 0s linear infinite;
  `
  const Animation8 = styled.div`
    animation: ${swipeDown} 10s 0s linear infinite;
  `

  return (
    <React.Fragment>
      <ThemeProvider theme={standardTheme}>
        <CookieConcent />
        <CssBaseline />
        <main className={classes.main}>
          {/* Hero unit */}
          <div className={classes.heroContent}>
            <div className={classes.shapeBackground}>
              <div
                className={classes.bigBubble}
                style={{
                  right: '5%',
                  top: '8vw',
                }}
              />
              <div
                className={classes.smallBubble}
                style={{
                  right: '35%',
                  top: '17vw',
                }}
              />
              <div
                className={classes.xlBubble}
                style={{
                  right: '20%',
                  top: '1vw',
                }}
              />

              <div
                className={classes.bigBubble}
                style={{
                  left: '10%',
                  top: '0vw',
                }}
              />
              <div
                className={classes.bigBubble}
                style={{
                  left: '25%',
                  top: '13vw',
                }}
              />
              <div
                className={classes.smallBubble}
                style={{
                  left: '25%',
                  top: '0vw',
                }}
              />
              <div
                className={classes.smallBubble}
                style={{
                  left: '5%',
                  top: '12vw',
                }}
              />
            </div>
            <Container maxWidth="lg">
              <Typography
                component="h2"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Create free animations
              </Typography>
              <Grid
                container
                style={{
                  zIndex: 100,
                }}
              >
                <Grid md={12} lg={12}>
                  <Container
                    maxWidth="sm"
                    style={{
                      zIndex: 100,
                      position: 'relative',
                      marginBottom: '32px',
                    }}
                  >
                    <Typography
                      style={{ marginTop: '16px' }}
                      variant="h5"
                      align="center"
                      paragraph
                    >
                      Watch the 51 second example, it's really this easy
                    </Typography>
                  </Container>
                </Grid>
                <Grid md={12} lg={12}>
                  <Container
                    style={{
                      maxWidth: '640px',
                      position: 'relative',
                      width: '100%',
                      paddingBottom: 'min(56.25%, 360px)',
                      height: 0,
                    }}
                  >
                    <iframe
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      title="How to create animations in 60 seconds"
                      id="ytplayer"
                      type="text/html"
                      src="https://www.youtube.com/embed/i792gyGBTYI"
                      frameborder="0"
                      allowFullScreen
                    ></iframe>
                  </Container>
                  <Container
                    maxWidth="sm"
                    style={{
                      zIndex: 100,
                      position: 'relative',
                    }}
                  >
                    <Typography
                      style={{ marginTop: '30px' }}
                      variant="h5"
                      align="center"
                      paragraph
                    >
                      Create custom animated popups - customize time interval,
                      colors, text, animations and more! Fast and easy to use -
                      just paste a link into OBS
                    </Typography>
                  </Container>
                </Grid>
              </Grid>

              {true && (
                <Link href="/signup">
                  <SignupButton
                    className={classes.signupButton}
                    style={{ marginTop: '32px' }}
                  />
                </Link>
              )}
              <Typography
                style={{ marginTop: '32px', marginBottom: '0px' }}
                variant="h5"
                align="center"
                paragraph
              >
                Or see animations below
              </Typography>

              <Typography variant="h5" align="center" paragraph>
                v v v
              </Typography>

              {false && <ProductHowItWorks />}
            </Container>

            {true && (
              <svg
                className={classes.wave1}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="100 30 900 220"
              >
                <path
                  fill={popupsBackground}
                  fillOpacity="1"
                  d="M0,160L60,154.7C120,149,240,139,360,154.7C480,171,600,213,720,218.7C840,224,960,192,1080,197.3C1200,203,1320,245,1380,266.7L1440,288L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                ></path>
              </svg>
            )}

            {false && (
              <svg
                className={classes.wave1}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
              >
                <path
                  fill="#0099ff"
                  fillOpacity="1"
                  d="M0,96L80,122.7C160,149,320,203,480,202.7C640,203,800,149,960,122.7C1120,96,1280,96,1360,96L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                ></path>
              </svg>
            )}
          </div>
          {false && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#bbbbbb"
                fillOpacity="1"
                d="M0,288L60,288C120,288,240,288,360,261.3C480,235,600,181,720,176C840,171,960,213,1080,218.7C1200,224,1320,192,1380,176L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
              ></path>
            </svg>
          )}
          {false && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#bbbbbb"
                fillOpacity="1"
                d="M0,160L60,138.7C120,117,240,75,360,74.7C480,75,600,117,720,149.3C840,181,960,203,1080,202.7C1200,203,1320,181,1380,170.7L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
              ></path>
            </svg>
          )}
          <div className={classes.heroContent2}>
            <Container className={classes.cardGrid} maxWidth="xl">
              {/* End hero unit */}

              <Grid container spacing={4}>
                {false && (
                  <Grid item key={1} xs={12} xl={12} id="Text">
                    <Typography variant="h4" className={classes.categoryTitle}>
                      Examples of created popups
                    </Typography>
                  </Grid>
                )}
                <Grid
                  style={{ color: 'black', marginBottom: '8px' }}
                  item
                  key={1}
                  xs={12}
                  xl={12}
                  id="Text"
                >
                  <Typography variant="h3" align="center" paragraph>
                    Example animations
                  </Typography>
                </Grid>
                {true && (
                  <DisplayCardTransparent
                    data={
                      getOriginDomain() +
                      '/animation/imageWithText.php?e1_animation=instant&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&i1_path=&undefined_undefined=undefined&i1_width=70&b1_include=true&e1_textColor=000000&e1_text=Some text here&e1_fontSize=9&e1_font=Arial, sans-serif&e1_width=70&b1_animation=zoom&b1_backgroundColor=ffffff&b1_width=85&b1_height=80&b1_borderR=5&b1_opacity=100&loop=yes'
                    }
                    header="Image"
                    link="/text"
                  />
                )}
                {false && (
                  <DisplayCard
                    data={
                      getOriginDomain() +
                      '/animation/separatedDoubleText.php?e1_animationInterval=30&e1_animationDelay=10&e1_textColor=000000&e1_text=Choose different animations &e1_fontSize=5&e1_font=Arial Black, Gadget, sans-serif&e1_animation=fade&e1_animationDuration=10&e1_delay=0&e2_textColor=7cb196&e2_text=Different fonts, colors and more 😎&e2_fontSize=7.875&e2_font=Courier New, monospace&e2_animation=swipeLeft&e2_animationDuration=8&e2_delay=1.5&loop=yes'
                    }
                    header="Text"
                    link="/text"
                  />
                )}
                {false && (
                  <DisplayCard
                    data={
                      getOriginDomain() +
                      '/animation/doubleText.php?e2_text=These are examples &e1_animationDuration=10&e2_fontSize=9&e3_text=In use, they can show up for example every 20 minutes&e3_fontSize=6.3&e1_animation=zoom&e1_animationInterval=30&e1_animationDelay=10&e3_textColor=000 &loop=yes'
                    }
                    header="Text"
                    link="/doubleText"
                  />
                )}
                <DisplayCardTransparent
                  data={
                    getOriginDomain() +
                    '/animation/socialsRound.php?e1_animationInterval=30&e1_animationDelay=10&e1_animationDuration=8&e2_width=18.75&f1_fontSize=9.450000000000001&f1_textColor=000000&e1_animation=fade&f1_font=Open Sans Condensed, sans-serif&b1_backgroundColor=ffffff&b1_width=70&b1_borderR=50&b1_opacity=100&e2_include=1&e2_text=Your Twitter&e3_include=1&e3_text=Your Youtube&e4_include=1&e4_text=Your Instagram&b1_includeBgShadow=1&loop=yes'
                  }
                  header="Social media"
                  body="Showcase your different social media channels, background is black because of the white background, white background is available (and looks better)."
                  link="/socials"
                  quotaCost={2}
                />
                <DisplayCardTransparent
                  data={
                    getOriginDomain() +
                    '/animation/primeGaming.php?e1_animationInterval=30&e1_animationDelay=10&b1_animation=swipeUp&e1_animation=instant&e1_animationDuration=10&b1_include=1&b1_backgroundColor=ffffff&b1_width=90&b1_height=55&b1_borderR=5&b1_opacity=100&b1_includeBgShadow=1&e1_textColor=000000&e1_text=Subscribe for free with&e1_fontSize=9&e1_font=Open Sans Condensed, sans-serif&e2_width=55&loop=yes'
                  }
                  header="Twitch Prime"
                  link="/followOld"
                />
                {false && (
                  <DisplayCard
                    data={
                      getOriginDomain() +
                      '/animation/latestVideo.php?e1_animation=circlePop&e1_animationDuration=10&e1_animationInterval=30&e1_animationDelay=10&e2_videoPageLink=youtube.com/user/ernwillburn/videos&e1_text=Latest YouTube !video &e1_fontSize=8.1&e2_width=70&e3_fontSize=5&e3_width=65&loop=yes'
                    }
                    header="Latest YouTube video (Updates automatically)"
                    link="/latestVideo"
                  />
                )}
                <DisplayCardTransparent
                  data={
                    getOriginDomain() +
                    '/animation/latestVideo.php?e2_videoPageLink=UC-R9KsHOOphWhr8pxkbsN1A&e1_relevanceLimit=14&e1_animationDuration=12&e1_animationInterval=30&e1_animationDelay=10&e1_animation=instant&b1_animation=circlePop&c1_textColor=000000&b1_include=1&b1_backgroundColor=ffffff&b1_width=90&b1_height=90&b1_borderR=4&b1_opacity=100&e1_text=Latest YouTube !video&e1_fontSize=8.1&e1_font=Open Sans Condensed, sans-serif&e2_width=70&e3_fontSize=4.5&e3_width=70&e3_font=Open Sans Condensed, sans-serif&b1_includeBgShadow=1&loop=yes'
                  }
                  header="Latest YouTube video (updates automatically)"
                  link="/latestVideo"
                />
                <div></div>
                {false && (
                  <svg
                    className={classes.wave2}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="100 40 1140 320"
                  >
                    <path
                      fill={popupsBackground}
                      d="M0,128L80,149.3C160,171,320,213,480,202.7C640,192,800,128,960,133.3C1120,139,1280,213,1360,250.7L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                    ></path>
                  </svg>
                )}
                {false && (
                  <Container maxWidth="md">
                    <Grid
                      style={{
                        color: 'black',
                        marginBottom: '80px',
                        marginTop: '20px',
                        zIndex: '1000000',
                      }}
                      item
                      key={1}
                      xs={12}
                      xl={12}
                      id="Text"
                    >
                      <Typography variant="h4" align="center" paragraph>
                        The animations can show up for example every 15 minutes,
                        you decide.
                      </Typography>
                    </Grid>
                  </Container>
                )}
              </Grid>
            </Container>
          </div>
        </main>

        {false && (
          <div className={classes.heroContent1}>
            <Container maxWidth="lg">
              <Grid container spacing={12}>
                <Grid item key={3} xs={12} xl={12} id="Text">
                  <Typography variant="h4" align="center" paragraph>
                    Choose from many different animations
                  </Typography>
                </Grid>
                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation2 className={classes.animationDisplayItemDisplay}>
                    <Typography variant="h4" classes>
                      Fade
                    </Typography>
                  </Animation2>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation1
                    className={classes.animationDisplayItemDisplay}
                  ></Animation1>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation3
                    className={classes.animationDisplayItemDisplay}
                  ></Animation3>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation4
                    className={classes.animationDisplayItemDisplay}
                  ></Animation4>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation5
                    className={classes.animationDisplayItemDisplay}
                  ></Animation5>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation10
                    className={classes.animationDisplayItemDisplay}
                  ></Animation10>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation11
                    className={classes.animationDisplayItemDisplay}
                  ></Animation11>
                </Grid>

                <Grid
                  item
                  key={4}
                  xs={6}
                  xl={3}
                  className={classes.animationDisplayItem}
                >
                  <Animation12
                    className={classes.animationDisplayItemDisplay}
                  ></Animation12>
                </Grid>

                {false && (
                  <Grid
                    item
                    key={4}
                    xs={6}
                    xl={3}
                    className={classes.animationDisplayItem}
                  >
                    <Animation8
                      className={classes.animationDisplayItemDisplay}
                    ></Animation8>
                  </Grid>
                )}
                {false && (
                  <Grid
                    item
                    key={4}
                    xs={6}
                    xl={3}
                    className={classes.animationDisplayItem}
                  >
                    <Animation6
                      className={classes.animationDisplayItemDisplay}
                    ></Animation6>
                  </Grid>
                )}
                {false && (
                  <Grid
                    item
                    key={4}
                    xs={6}
                    xl={3}
                    className={classes.animationDisplayItem}
                  >
                    <Animation7
                      className={classes.animationDisplayItemDisplay}
                    ></Animation7>
                  </Grid>
                )}
                {false && (
                  <Grid
                    item
                    key={4}
                    xs={6}
                    xl={3}
                    className={classes.animationDisplayItem}
                  >
                    <Animation9
                      className={classes.animationDisplayItemDisplay}
                    ></Animation9>
                  </Grid>
                )}
              </Grid>
            </Container>
          </div>
        )}

        <div className={classes.heroContent1}>
          {true && (
            <svg
              className={classes.wave2}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="100 40 1140 320"
            >
              <path
                fill={popupsBackground}
                fillOpacity="1"
                d="M0,128L80,149.3C160,171,320,213,480,202.7C640,192,800,128,960,133.3C1120,139,1280,213,1360,250.7L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>
            </svg>
          )}
          {false && (
            <svg
              className={classes.wave2}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="250 0 1040 320"
            >
              <path
                fill="#0099ff"
                fillOpacity="1"
                d="M0,320L120,282.7C240,245,480,171,720,165.3C960,160,1200,224,1320,256L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
              ></path>
            </svg>
          )}

          {false && (
            <svg
              className={classes.wave2}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="250 0 1040 320"
            >
              <path
                fill="#0099ff"
                fillOpacity="1"
                d="M0,320L120,282.7C240,245,480,171,720,165.3C960,160,1200,224,1320,256L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
              ></path>
            </svg>
          )}

          {false && (
            <svg
              className={classes.wave2}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
            >
              <path
                fill="#0099ff"
                fillOpacity="1"
                d="M0,96L80,96C160,96,320,96,480,122.7C640,149,800,203,960,208C1120,213,1280,171,1360,149.3L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>
            </svg>
          )}
          <div className={classes.shapeBackground}>
            <div
              className={classes.bigBubble}
              style={{
                left: '5%',
                top: '6vw',
              }}
            />
            <div
              className={classes.smallBubble}
              style={{
                left: '15%',
                top: '18vw',
              }}
            />
            <div
              className={classes.smallBubble}
              style={{
                left: '32%',
                top: '35vw',
              }}
            />
            <div
              className={classes.xlBubble}
              style={{
                left: '20%',
                top: '-10vw',
              }}
            />
            <div
              className={classes.bigBubble}
              style={{
                left: '19%',
                top: '28vw',
              }}
            />
            <div
              className={classes.xlBubble}
              style={{
                left: '-6%',
                top: '30vw',
              }}
            />

            <div
              className={classes.xlBubble}
              style={{
                right: '3%',
                top: '24vw',
              }}
            />

            <div
              className={classes.bigBubble}
              style={{
                right: '8%',
                top: '-1vw',
              }}
            />
            <div
              className={classes.bigBubble}
              style={{
                right: '17%',
                top: '13vw',
              }}
            />
            <div
              className={classes.bigBubble}
              style={{
                right: '23%',
                top: '33vw',
              }}
            />
            <div
              className={classes.bigBubble}
              style={{
                right: '25%',
                top: '-8vw',
              }}
            />
            <div
              className={classes.smallBubble}
              style={{
                right: '5%',
                top: '12vw',
              }}
            />
          </div>

          <Container className={classes.benefits} maxWidth="md">
            <Grid container spacing={8}>
              <Grid item key={3} xs={12} xl={12} id="Text">
                <Typography variant="h3" align="center" paragraph>
                  🚀 Benefits 🚀
                </Typography>
              </Grid>
              <Grid item key={4} xs={12} xl={6} id="Text">
                <Typography variant="h4" align="center" paragraph>
                  Automation
                </Typography>
                <Typography variant="h5" align="center" paragraph>
                  Having a popup for something is easier than verbally reminding
                  people
                </Typography>
              </Grid>
              <Grid item key={5} xs={12} xl={6} id="Text">
                <Typography variant="h4" align="center" paragraph>
                  Interaction
                </Typography>
                <Typography variant="h5" align="center" paragraph>
                  Create a more interactive experience for your viewers
                </Typography>
              </Grid>

              <Grid item key={6} xs={12} xl={6} id="Text">
                <Typography variant="h4" align="center" paragraph>
                  Customizability
                </Typography>
                <Typography variant="h5" align="center" paragraph>
                  Popups are customizable, you can choose colors, text,
                  animation and more!
                </Typography>
              </Grid>

              <Grid item key={7} xs={12} xl={6} id="Text">
                <Typography variant="h4" align="center" paragraph>
                  Timing
                </Typography>
                <Typography variant="h5" align="center" paragraph>
                  Show popup how often you want, every 13 minutes and 37
                  seconds? Sure!
                </Typography>
              </Grid>

              {false && (
                <Grid item key={8} xs={12} xl={6} id="Text">
                  <Typography variant="h4" align="center" paragraph>
                    Timing
                  </Typography>
                  <Typography variant="h5" align="center" paragraph>
                    Want cyan text to fade in after 13 minutes and 37 seconds?
                    Gotcha!
                  </Typography>
                </Grid>
              )}

              {true && (
                <Grid item key={1} xs={12} xl={12} id="Text">
                  <Typography variant="h4">Easy and fast setup!</Typography>
                </Grid>
              )}
              {false && <ProductHowItWorks />}

              {false && (
                <Grid item key={9} xs={12} xl={12} id="Text">
                  <Typography variant="h4" align="center" paragraph>
                    Free
                  </Typography>
                  <Typography variant="h5" align="center" paragraph>
                    ¯\_(ツ)_/¯
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Link href="/signup">
              <SignupButton className={classes.signupButton2} />
            </Link>
          </Container>
        </div>
        <Link href="/signin">
          <Button className={classes.signinButton}>
            <Typography align="center" gutterBottom>
              Sign in
            </Typography>
          </Button>
        </Link>

        {/* Footer */}
        <footer className={classes.footer}>
          <Typography variant="h6" align="center" gutterBottom></Typography>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Created by friendly zombies
          </Typography>
          <CopyrightFooter />
        </footer>
      </ThemeProvider>
      {/* End footer */}
    </React.Fragment>
  )
}
