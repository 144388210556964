import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

function About() {
  const classes = useStyles()
  return (
    <div className="App">
      <header className="App-header">
        <p>aaaaaaaaaaaaaaaaaaaaaabout</p>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.margin}
        >
          Start now
        </Button>
      </header>
    </div>
  )
}

export default About
