import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: 'green',
  },
  selectEmpty: {},
  labelText: {
    textAlign: 'left',
  },
}))

export default function ImageOption(props) {
  const Http = new XMLHttpRequest()
  const url = './backend/image/getImages.php'

  const [serverResponse, setServerResponse] = React.useState('')

  const [dropdownOptions, setDropdownOptions] = React.useState([])

  const [images, setImages] = React.useState('')

  const [hasSentRequest, setHasSentRequest] = React.useState(false)

  if (serverResponse == '') {
    Http.open('POST', url)
    Http.send()
    if (hasSentRequest == false) {
      setHasSentRequest(true)
    }
  }

  let serverImageLocation = '/uploads/'
  let response = ''

  console.log('sending server request')

  Http.onreadystatechange = (e) => {
    if (Http.readyState == XMLHttpRequest.DONE) {
      console.log('http response: ' + Http.responseText)
      response = Http.responseText

      var responseObj = JSON.parse(response)
      //let images = responseObj['images']

      setImages(responseObj['images'])

      if (response !== serverResponse) {
        setServerResponse(response)
      }

      let dropDownOptionsAfterServerResponse = []

      for (var i = 0; i < images.length; i++) {
        dropDownOptionsAfterServerResponse.push(
          <MenuItem
            key={images[i]['pathName']}
            value={serverImageLocation + images[i]['pathName']}
          >
            {images[i]['displayName']}
          </MenuItem>
        )
      }

      setDropdownOptions(dropDownOptionsAfterServerResponse)
    }
  }

  //response = '{"images":[{"displayName":"eyyy.PNG","pathName":"799a1fef90aa5de60cefe36efad57215_eyyy.PNG","uploadDate":"2020-08-18 11:44:35"},{"displayName":"eyyy.PNG","pathName":"9007ac1302f1c4f1e4ebd49a6ddb1bc2_eyyy.PNG","uploadDate":"2020-08-18 11:45:04"},{"displayName":"eyyy.PNG","pathName":"9779b3df804dd226bdebce587d807edc_eyyy.PNG","uploadDate":"2020-08-18 11:39:32"},{"displayName":"eyyy.PNG","pathName":"9a0d130d674f66768866662b3a43efc2_eyyy.PNG","uploadDate":"2020-08-18 11:44:27"}]}'

  //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //console.log(responseObj['images']);

  const classes = useStyles()

  const [choice, setChoice] = React.useState(props.defaultValue)
  const [valueChanged, setValueChanged] = React.useState(false)

  const handleChange = (name) => (event) => {
    setChoice(event.target.value)
    //TODO check if event.target.value is needed (setChoice is synchronous, so that choice can be used instead)
    setValueChanged(true)
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      event.target.value
    )
    console.log('image: ' + event.target.value)
  }

  let options = []

  if (props.options) {
    options = props.options
  }

  if (!valueChanged) {
    props.parentHandleUpdate(
      props.element + '_' + props.optionName,
      props.defaultValue
    )
  }

  const dropdownOptions2 = options.map((images) => (
    <MenuItem key={images['pathName']} value={images['pathName']}>
      {console.log('_________________')}
    </MenuItem>
  ))

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
      <div className={classes.root}>
        <FormControl className={classes.formControl} margin="dense">
          <Typography className={classes.labelText}>{props.title}</Typography>
          <Select
            value={choice}
            onChange={handleChange()}
            displayEmpty
            name="age"
            className={classes.selectEmpty}
            input={<OutlinedInput className={classes.field} />}
          >
            {dropdownOptions}
          </Select>
          <FormHelperText>{props.helpText}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  )
}

/* Usage example:
  <SelectOption
    element="em1"
    optionName="animation"
    parentHandleUpdate={props.alertParentOptionChange}
    title="Animation"
    options={[["zoom", "Zoom"],
              ["swipeRight", "Swipe right"],
              ["swipeLeft", "Swipe left"],
              ["swipeUp", "Swipe up"],
              ["swipeDown", "Swipe down"],
            ]}
    defaultValue="zoom"
  />
*/
