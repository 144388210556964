import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Card from '@material-ui/core/Card'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Copyright from '../components/common/Copyright'
import standardTheme from '../common/standardTheme'

import queryString from 'query-string'
const urlParameters = queryString.parse(window.location.search)

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundColor: '#f6f6f6',
      backgroundImage: `/*Created with svgbackgrounds.com*/ url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='432' height='432' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfbfb' stroke-width='3.3'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23fbfbfb'%3E%3Ccircle cx='769' cy='229' r='8'/%3E%3Ccircle cx='539' cy='269' r='8'/%3E%3Ccircle cx='603' cy='493' r='8'/%3E%3Ccircle cx='731' cy='737' r='8'/%3E%3Ccircle cx='520' cy='660' r='8'/%3E%3Ccircle cx='309' cy='538' r='8'/%3E%3Ccircle cx='295' cy='764' r='8'/%3E%3Ccircle cx='40' cy='599' r='8'/%3E%3Ccircle cx='102' cy='382' r='8'/%3E%3Ccircle cx='127' cy='80' r='8'/%3E%3Ccircle cx='370' cy='105' r='8'/%3E%3Ccircle cx='578' cy='42' r='8'/%3E%3Ccircle cx='237' cy='261' r='8'/%3E%3Ccircle cx='390' cy='382' r='8'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  card: {
    padding: '2vw',
    marginTop: '5vw',
    marginLeft: '3vw',
    marginRight: '3vw',
    boxShadow: '0 2px 10px rgba(0,0,0,.2)',
  },
  container: {
    marginTop: '10vw',
    padding: theme.spacing(0),
  },
  errorText: {
    marginTop: theme.spacing(3),
  },
}))

export default function SignIn() {
  const classes = useStyles()

  let url_string = window.location.href //window.location.href
  let url = new URL(url_string)
  let error = url.searchParams.get('error')
  let email = url.searchParams.get('email')

  return (
    <ThemeProvider theme={standardTheme}>
      <Container className={classes.container} component="main" maxWidth="sm">
        <Card className={classes.card}>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography style={{ fontWeight: 700 }} component="h1" variant="h5">
              Sign in
            </Typography>
            {urlParameters.updatedPassword === 'true' && (
              <Typography component="h5" variant="h6">
                (new password has been set)
              </Typography>
            )}
            {error && error === 'wrongpassword' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                Incorrect password
              </Typography>
            )}
            {error && error === 'nouserfound' && (
              <Typography
                component="h1"
                variant="h6"
                className={classes.errorText}
              >
                No user with that email exists
              </Typography>
            )}
            <form
              className={classes.form}
              action="/backend/includes/signin.php"
              method="post"
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                defaultValue={email ? email : ''}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="pwd"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {false && (
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                name="signin-submit"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container className={classes.flexContainer}>
                <Grid item>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {'Sign up for free'}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Card>
        <Box mt={6}>
          <Copyright />
        </Box>
      </Container>
    </ThemeProvider>
  )
}
